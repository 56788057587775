import { useNavigate } from "react-router-dom";
import { useGenresQuery } from "../../../hooks/searchHooks/useGenresQuery";
import { useServiceTypesQuery } from "../../../hooks/searchHooks/useServiceTypesQuery";
import { useUpdateSearchFilters } from "../../../hooks/searchHooks/useUpdateSearchFilters";
import { applyLocationFilter } from "../../../hooks/searchHooks/utils";
import { SCREENS } from "../../../routes/screens";
import { setAutoCompleteSuggestions } from "../../../store/actions/userSearch";
import { useAppDispatch } from "../../../store/hooks";
import {
  AutocompleteSuggestion,
  AutoCompleteTypeEnum,
} from "../../../store/models/autocomplete";
import {
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { MaxDistanceOptionValue } from "../SearchFilters/LocationFilterPopover/LocationFilterPopover.utils";
import { useCachedAnchorPoint } from "../SearchFilters/LocationFilterPopover/useLocationFilter";
import { EntitySearchResultListItem } from "./EntitySearchResultItem";
import "./EntitySearchResultList.css";
import {
  equipmentLabelFilterAtom,
  useEquipmentFilterQuery,
} from "../../../hooks/searchHooks/useEquipmentFilterQuery";
import { useSetAtom } from "jotai";

const TEST_PREFIX = "entity-search-result-list";

export interface EntitySearchResultListProps {
  className?: string;
  loading?: boolean;
  data: AutocompleteSuggestion[];
  onListItemClick: () => void;
}

export const EntitySearchResultList = ({
  className,
  data,
  onListItemClick,
  loading,
}: EntitySearchResultListProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { updateGenresFilterOnSuggestion } = useGenresQuery();
  const { setServiceType } = useServiceTypesQuery();
  const updateSearchFiltersAndNavigate = useUpdateSearchFilters();
  const { updateCachedAnchorPoint } = useCachedAnchorPoint();
  const { setEquipmentFilter } = useEquipmentFilterQuery();
  const setEquipmentLabelFilter = useSetAtom(equipmentLabelFilterAtom);

  const onAutocompleteItemClick = (suggestion: AutocompleteSuggestion) => {
    emitAnalyticsTrackingEvent("search_autocomplete_selection", {
      autocomplete_suggestion: suggestion,
    });
    const { type } = suggestion;
    const isUser = type === AutoCompleteTypeEnum.USER;
    const isStudio = type === AutoCompleteTypeEnum.STUDIO;

    let newLocationPathname = location.pathname;
    if (
      ![SCREENS.PAGINATED_ENGINEERS, SCREENS.PAGINATED_STUDIOS].includes(
        location.pathname as SCREENS,
      )
    ) {
      newLocationPathname = SCREENS.SEARCH;
    }

    // clear the search term atom
    onListItemClick();

    if (isUser) {
      const { username } = suggestion;
      navigate(getProfileScreenRoute(username));
    } else if (isStudio) {
      const { username } = suggestion;
      navigate(getStudioScreenRoute(username));
    } else if (type === AutoCompleteTypeEnum.LOCATION) {
      const updatedSearchQueryObject = applyLocationFilter(
        {},
        {
          newLatLng: {
            lat: parseFloat(suggestion.latitude),
            lng: parseFloat(suggestion.longitude),
          },
          newMaxDistance: MaxDistanceOptionValue.FIFTY,
        },
      );
      updateCachedAnchorPoint({
        lat: parseFloat(suggestion.latitude),
        lng: parseFloat(suggestion.longitude),
        formatted_address: suggestion.label,
      });

      updateSearchFiltersAndNavigate({
        updatedSearchQueryObject,
        resetPagination: true,
        newPathname: newLocationPathname,
      });
    } else if (type === AutoCompleteTypeEnum.GENRE) {
      updateGenresFilterOnSuggestion(
        suggestion,
        newLocationPathname,
        updateSearchFiltersAndNavigate,
      );
    } else if (type === AutoCompleteTypeEnum.SERVICE_TYPE) {
      setServiceType(suggestion.value, newLocationPathname);
    } else if (type === AutoCompleteTypeEnum.EQUIPMENT) {
      setEquipmentLabelFilter(suggestion.label);
      setEquipmentFilter(suggestion.id, newLocationPathname);
    } else {
      if (location.pathname !== SCREENS.SEARCH && !isUser && !isStudio) {
        navigate(SCREENS.SEARCH);
      }
    }

    dispatch(
      setAutoCompleteSuggestions({
        autocompleteSuggestion: suggestion,
      }),
    );
  };

  if (!loading && data.length === 0) {
    return null;
  }
  return (
    <div
      style={
        !loading && data.length < 5
          ? {
              height: "fit-content",
              maxHeight: "unset",
            }
          : {}
      }
      data-testid={`${TEST_PREFIX}-container`}
      className={`entity-search-result-list ${loading ? "loading-autocomplete-results" : ""} ${className}`}
    >
      {loading && <SoundWaveLoader height={100} width={100} />}
      {!loading &&
        data.map((item: AutocompleteSuggestion, index: number) => {
          return (
            <EntitySearchResultListItem
              key={index}
              onListItemClick={onAutocompleteItemClick}
              autocompleteItem={item}
            />
          );
        })}
    </div>
  );
};
