import { LOAD_PROFILE_CURRENCY } from "../../../store/utils/routes";
import { makeBackendPostCallWithJsonResponse } from "../../../store/utils/fetch";
import { promiseEngineEarsHandler } from "../../helpers";
import { ProfileCurrency } from "../../../store/models/profile";

interface PostProfileCurrencyParams {
  profileId: number;
  currencyCode: string;
}

interface PostProfileCurrencyResponse {
  data: ProfileCurrency;
  count: number;
}

export const postProfileCurrency = async ({
  currencyCode,
  profileId,
}: PostProfileCurrencyParams): Promise<PostProfileCurrencyResponse> => {
  return makeBackendPostCallWithJsonResponse<PostProfileCurrencyResponse>(
    LOAD_PROFILE_CURRENCY,
    {
      profile_id: profileId,
      currency: currencyCode,
    },
  ).then(promiseEngineEarsHandler);
};
