import FormSelect from "../../../elements/Form/FormSelect";
import useActiveProfileCurrencyQuery from "../../../../hooks/userHooks/useActiveProfileCurrencyQuery";
import { FormEventHandler, useCallback, useMemo, useState } from "react";
import { Box, Checkbox, FormControlLabel, Grid2 } from "@mui/material";
import { Text, TEXT_WEIGHT } from "../../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../../core-ui/components/Text/TextUtils";
import { atomWithReset } from "jotai/utils";
import { useAtom } from "jotai";
import useProfileCurrencyMutation from "../../../../hooks/userHooks/useProfileCurrencyMutation";
import { ProfileType, SlimProfileBase } from "../../../../store/models/base";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../../../constants/queryKeys";
import Form from "../../../elements/Form/Form";
import { Button } from "../../../core-ui/components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/pro-solid-svg-icons";
import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { PennyDollarFormatter } from "../../../../store/utils/formatUtils";
import { AfterFirstDigitRegex } from "../../../../constants/regex";
import Link from "../../../elements/Link/Link";
import { ToolTipTextArea } from "../../ToolTipTextArea/ToolTipTextArea";

interface FormElements extends HTMLFormControlsCollection {
  currency: HTMLInputElement;
}

interface FormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

const currencyAtom = atomWithReset("");

interface ProfileCurrencySelectionModalProps {
  activeProfile: SlimProfileBase;
}

export const ProfileCurrencySelection = ({
  activeProfile,
}: ProfileCurrencySelectionModalProps) => {
  const { data, isLoading, refetch } = useActiveProfileCurrencyQuery();
  const { mutateAsync: updateProfileCurrency, isPending } =
    useProfileCurrencyMutation();
  const items = useMemo(() => {
    if (data?.valid_currencies) {
      const itemDict: Record<string, string> = {};
      Object.entries(data.valid_currencies).forEach(([code, data]) => {
        itemDict[code] =
          `${data.name} (${code} ${PennyDollarFormatter(code).format(0).replace(AfterFirstDigitRegex, "")})`;
      });
      return itemDict;
    }
    return {};
  }, [data]);
  const queryClient = useQueryClient();
  const [currency, setCurrency] = useAtom(currencyAtom);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const handleSubmit: FormEventHandler<FormElement> = useCallback(
    (e) => {
      e.preventDefault();
      if (!activeProfile.profile_id) {
        // Somehow the user is seeing this modal while not being logged in
        return;
      }
      const { currency } = e.currentTarget.elements;
      const item = data?.valid_currencies[currency.value];
      if (!item) {
        return;
      }
      void updateProfileCurrency({
        profileId: activeProfile.profile_id,
        currencyCode: item.code,
      }).then(async ({ data: updatedData, count }) => {
        const currencyName = items[updatedData.currency];
        toast.success(
          `Successfully updated currency to ${currencyName}.${count > 0 ? ` This has disabled ${count} of your services. Please review and re-enable to allow bookings.` : ""}`,
        );
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_ENGINEER_SERVICES],
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_USER_PROFILE],
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_STUDIO_ROOMS],
        });
        await refetch();
      });
    },
    [
      data?.valid_currencies,
      activeProfile.profile_id,
      updateProfileCurrency,
      queryClient,
      refetch,
      items,
    ],
  );

  const isNewCurrency = useMemo(() => {
    if (currency === "" || data?.currency === "") {
      return false;
    }
    return currency !== data?.currency;
  }, [data?.currency, currency]);

  const isStudio = useMemo(
    () => activeProfile.type == ProfileType.STUDIO,
    [activeProfile.type],
  );
  return (
    <Box
      component={Form}
      onSubmit={handleSubmit}
      display={"flex"}
      flexDirection={"column"}
    >
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <Text variant={TextStyleVariant.P1} weight={TEXT_WEIGHT.SEMI_BOLD}>
            Billing Currency
          </Text>
        </Grid2>
        <Grid2 size={12}>
          <Text>
            Select a currency for how you charge for your{" "}
            {isStudio ? "studio" : "engineering"} services.
          </Text>
        </Grid2>
        <Grid2 size={12}>
          <Link
            to={
              isStudio
                ? "https://support.engineears.com/en/knowledge-base/how-do-i-update-my-billing-currency-as-a-studio"
                : "https://support.engineears.com/en/knowledge-base/how-do-i-update-my-billing-currency-as-an-engineer"
            }
            target={"_blank"}
            sx={(theme) => ({
              color: theme.palette.text.primary,
              textDecorationColor: theme.palette.text.primary,
            })}
          >
            Learn more
          </Link>
        </Grid2>
        <Grid2 size={12}>
          <Text weight={TEXT_WEIGHT.SEMI_BOLD}>
            Currency for your service rates{" "}
            <ToolTipTextArea
              text={
                "Updating the billing currency for your services will only apply to transactions moving forward, and will not impact historical transactions."
              }
            />
          </Text>
        </Grid2>
        <Grid2 size={12}>
          <FormSelect
            SelectProps={{
              renderValue: () =>
                items[currency || data?.currency || ""] || "N/A",
            }}
            value={currency || data?.currency}
            items={items}
            onChange={(e) => setCurrency(e.target.value)}
            name={"currency"}
            fullWidth
            disabled={isLoading || isPending}
          />
        </Grid2>
        <Grid2
          size={12}
          component={Box}
          sx={(theme) => ({
            backgroundColor: theme.palette.warning.main,
            borderRadius: theme.border.radius["sm"],
            boxSizing: "border-box",
            padding: theme.spacing(1),
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
          })}
        >
          <Text color={TextColor.BLACK}>
            Changing the currency will disable all of your associated Recording
            and Engineering Services.
          </Text>
          <Text color={TextColor.BLACK}>
            Each one will need to be manually re-enabled, so you can confirm the
            pricing in the newly selected currency.
          </Text>
          <FormControlLabel
            control={
              <Checkbox
                disableRipple
                disableTouchRipple
                checked={acceptedTerms}
                value={acceptedTerms}
                color={"primary"}
                onChange={(e) => {
                  const checked = e.target.checked;
                  setAcceptedTerms(checked);
                }}
                checkedIcon={
                  <FontAwesomeIcon size={"lg"} icon={faSquareCheck} />
                }
                icon={<FontAwesomeIcon size={"lg"} icon={faSquare} />}
              />
            }
            label="I understand that my services will be disabled until I re-enable them"
          />
        </Grid2>
        <Grid2 size={12}>
          <Button
            type={"submit"}
            loading={isLoading || isPending}
            fullWidth
            disabled={!isNewCurrency || !acceptedTerms}
          >
            Update Currency
          </Button>
        </Grid2>
      </Grid2>
    </Box>
  );
};
