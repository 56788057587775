import { faLink } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/sharp-solid-svg-icons";
import { Box, Grid, useTheme } from "@mui/material";
import { ImgHTMLAttributes, useMemo, useState } from "react";
import useModal from "../../../../hooks/useModal";
import EntityVideo from "../../../../store/models/entityVideo";
import { ProjectType } from "../../../../store/models/project";
import { RecordingService } from "../../../../store/models/recording";
import Service from "../../../../store/models/service";
import { ModalHeader } from "../../../core-ui/components/BaseModal/BaseModal.styles";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { FixedMinWidthButton } from "../../../core-ui/components/Button/FixedMinWidthButton";
import { ResponsiveDialog } from "../../../core-ui/components/ResponsiveDialog/ResponsiveDialog";
import { Text, TEXT_WEIGHT } from "../../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../../core-ui/components/Text/TextUtils";
import { GenerateBookingLinkModal } from "../../GenerateBookingLinkModal/GenerateBookingLinkModal";
import { ToolTipTextArea } from "../../ToolTipTextArea/ToolTipTextArea";
import { WhatsIncludedListItem } from "../../WhatsIncludedListItem/WhatsIncludedListItem";
import { FeaturedVideoModal } from "./FeaturedVideoModal";
import { ServiceCardImage } from "./ServiceCardImage";
import {
  ServiceCardBaseModalContainer,
  ServiceCardBenefitsContainer,
  ServiceCardColumnContainer,
  ServiceCardInfoContainer,
  ServiceCardQuestionMarkIcon,
  ServiceCardRowContainer,
  ServiceCardV2Container,
} from "./ServiceCardV2.styles";

export interface ServiceCardV2Props {
  discountedRate?: string;
  originalRate: string;
  onClick: () => void;
  serviceName: string;
  serviceDescription: string;
  includedInServiceText: string[];
  userOnOwnProfile: boolean;
  disabled: boolean;
  editMode: boolean;
  imageProps: ImgHTMLAttributes<HTMLImageElement>;
  isLoading: boolean;
  service: RecordingService | Service;
  featuredVideo: EntityVideo | undefined | null;
  isNotBooking?: boolean;
}

export const ServiceCardV2 = ({
  discountedRate,
  originalRate,
  onClick,
  serviceName,
  serviceDescription,
  includedInServiceText,
  userOnOwnProfile,
  disabled,
  editMode,
  imageProps,
  isLoading,
  isNotBooking,
  service,
  featuredVideo,
}: ServiceCardV2Props) => {
  const { service_type: serviceType, description } = service;
  const theme = useTheme();
  const {
    isOpen: isDescriptionModalOpen,
    openModal: openDescriptionModal,
    closeModal: closeDescriptionModal,
  } = useModal();
  const {
    isOpen: isDiscountedModalOpen,
    closeModal: closeDiscountedLinkModal,
    openModal: openDiscountedLinkModal,
  } = useModal();
  const {
    isOpen: isVideoPreviewModalOpen,
    openModal: openVideoPreviewModal,
    setIsOpen: setIsVideoPreviewModalOpen,
  } = useModal();
  const [expandedDescription, setExpandedDescription] = useState(false);
  const isDescriptionExpandable =
    description?.length && description.length > MAX_DESCRIPTION_LENGTH;
  const displayedDescription =
    !expandedDescription && isDescriptionExpandable
      ? description?.slice(0, MAX_DESCRIPTION_LENGTH)
      : description;
  const buttonText = useMemo(() => {
    if (editMode) {
      if (isNotBooking) {
        return "Not Booking";
      }
      return "Edit Service";
    }
    if (userOnOwnProfile) {
      return "Generate Booking";
    }
    return "Book now";
  }, [editMode, userOnOwnProfile, isNotBooking]);

  const renderRate = () => {
    if (!discountedRate) {
      return (
        <Text variant={TextStyleVariant.P2}>
          <strong style={{ color: "inherit" }}>{originalRate}</strong>{" "}
          {serviceType === ProjectType.RECORDING ? "/hour" : "/song"}
        </Text>
      );
    }

    return (
      <Box sx={{ display: "flex", columnGap: "2px" }}>
        <Text
          style={{ textDecorationLine: "line-through" }}
          variant={TextStyleVariant.P2}
        >
          {originalRate}{" "}
        </Text>
        <Text variant={TextStyleVariant.P2}>
          <strong style={{ color: "inherit" }}>{discountedRate}</strong>{" "}
          {serviceType === ProjectType.RECORDING ? "/hour" : "/song"}
        </Text>
      </Box>
    );
  };

  return (
    <>
      <ServiceCardV2Container className="engineering-service-card">
        <div>
          <Text bold variant={TextStyleVariant.S3} sx={{ textAlign: "center" }}>
            {serviceName}
          </Text>
          <Text
            variant={TextStyleVariant.P3}
            color={TextColor.SECONDARY}
            sx={{ textAlign: "center" }}
          >
            {serviceDescription}
          </Text>
        </div>
        <ServiceCardImage
          featuredVideo={featuredVideo}
          imageProps={imageProps}
          openVideoPreviewModal={openVideoPreviewModal}
          serviceType={serviceType}
          userOnOwnProfile={userOnOwnProfile}
        />
        <ServiceCardColumnContainer>
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              columnGap: "12px",

              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
            })}
          >
            <FixedMinWidthButton
              disabled={disabled || isLoading || (isNotBooking && editMode)}
              variant={ButtonVariant.BLACK}
              onClick={onClick}
              loading={isLoading}
            >
              {buttonText}
            </FixedMinWidthButton>

            {/* Temporarily hide this feature from Recording Service */}
            {editMode && serviceType !== ProjectType.RECORDING && (
              <ToolTipTextArea text="Create a discounted service link">
                <Button
                  variant={ButtonVariant.BLACK_OUTLINED_BUTTON_BLACK_HOVER}
                  onClick={openDiscountedLinkModal}
                  style={{ padding: "0 11px" }}
                >
                  <FontAwesomeIcon icon={faLink} width={20} height={18} />
                </Button>
              </ToolTipTextArea>
            )}
          </Box>

          <ServiceCardRowContainer>
            {renderRate()}
            <Button
              style={{ alignItems: "flex-end", minHeight: "unset" }}
              variant={ButtonVariant.UNSTYLED}
              onClick={openDescriptionModal}
            >
              <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                <Text
                  variant={TextStyleVariant.P2}
                  weight={TEXT_WEIGHT.SEMI_BOLD}
                >
                  {" "}
                  What&apos;s Included{" "}
                </Text>
                <ServiceCardQuestionMarkIcon
                  style={{ marginLeft: "4px" }}
                  icon={faCircleQuestion}
                />
              </Box>
            </Button>
          </ServiceCardRowContainer>
        </ServiceCardColumnContainer>
      </ServiceCardV2Container>

      {isDiscountedModalOpen && (
        <GenerateBookingLinkModal
          showModal={isDiscountedModalOpen}
          serviceType={serviceType}
          maxServicePrice={
            (Number(service.service_rate?.price),
            Number(service.service_rate?.label_price))
          }
          closeModal={closeDiscountedLinkModal}
        />
      )}
      <ResponsiveDialog
        titleProps={{
          children: <ModalHeader>{serviceName}</ModalHeader>,
        }}
        open={isDescriptionModalOpen}
        fullWidth
        onClose={closeDescriptionModal}
        maxWidth={"md"}
        actionsProps={{
          children: (
            <FixedMinWidthButton
              disabled={disabled || isLoading || (isNotBooking && editMode)}
              variant={ButtonVariant.PRIMARY}
              onClick={() => {
                onClick();
                closeDescriptionModal();
              }}
              loading={isLoading}
            >
              {buttonText}
            </FixedMinWidthButton>
          ),
          sx: {
            justifyContent: "center",
            padding: "16px",
          },
        }}
        hideCloseAction
        contentProps={{
          children: (
            <ServiceCardBaseModalContainer>
              <Text variant={TextStyleVariant.P1}>{serviceDescription}</Text>
              <Grid container rowSpacing={6} columnSpacing={5}>
                <Grid item xs={12} sm={6}>
                  <ServiceCardInfoContainer>
                    <Text bold variant={TextStyleVariant.P1}>
                      What&apos;s included:
                    </Text>
                    <ServiceCardBenefitsContainer>
                      {includedInServiceText.map((text, index) => (
                        <WhatsIncludedListItem
                          key={index}
                          whatsIncludedBulletPoint={text}
                        />
                      ))}
                    </ServiceCardBenefitsContainer>
                  </ServiceCardInfoContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "24px",
                    }}
                  >
                    {displayedDescription && (
                      <ServiceCardInfoContainer>
                        <Text bold variant={TextStyleVariant.P1}>
                          Service description:
                        </Text>
                        <Text
                          color={TextColor.SECONDARY}
                          style={{
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {displayedDescription}
                          {isDescriptionExpandable && "..."}
                          {isDescriptionExpandable && (
                            <Button
                              variant={ButtonVariant.TEXT}
                              style={{
                                display: "inline",
                                minHeight: "unset",
                                color: theme.palette.info.main,
                              }}
                              onClick={() =>
                                setExpandedDescription((ex) => !ex)
                              }
                            >
                              read {expandedDescription ? "less" : "more"}
                            </Button>
                          )}
                        </Text>
                      </ServiceCardInfoContainer>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "4px",
                        flexWrap: "wrap",
                      }}
                    >
                      <Text
                        weight={TEXT_WEIGHT.SEMI_BOLD}
                        variant={TextStyleVariant.P2}
                      >
                        Price:{" "}
                      </Text>
                      {renderRate()}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </ServiceCardBaseModalContainer>
          ),
        }}
      />

      {isVideoPreviewModalOpen && featuredVideo && (
        <FeaturedVideoModal
          buttonText={buttonText}
          disabled={disabled}
          isLoading={isLoading}
          isVideoPreviewModalOpen={isVideoPreviewModalOpen}
          onClick={onClick}
          serviceName={serviceName}
          setIsVideoPreviewModalOpen={setIsVideoPreviewModalOpen}
          featuredVideo={featuredVideo}
        />
      )}
    </>
  );
};

const MAX_DESCRIPTION_LENGTH = 315;
