import { styled } from "@mui/material";

export const TooltipHelperContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: "fit-content",
  width: "fit-content",
});

export const TooltipText = styled("span")(({ theme }) => ({
  visibility: "hidden",
  backgroundColor: theme.palette.customColor.midgroundColor,
  border: `2px solid ${theme.palette.divider}`,
  color: theme.palette.text.primary,
  flexWrap: "nowrap",
  borderRadius: theme.border.radius["sm"],
  position: "absolute",
  transform: "translateY(5px)",
  zIndex: "var(--tooltip-text-z-index)",
  cursor: "auto",
  padding: "8px 12px",
  boxSizing: "border-box",
  minWidth: "100px",
  width: "max-content",
}));
