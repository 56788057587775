import { LOAD_PROFILE_CURRENCY } from "../../../store/utils/routes";
import { makeBackendGetCallWithJsonResponse } from "../../../store/utils/fetch";
import { ProfileCurrency } from "../../../store/models/profile";
import { promiseEngineEarsHandler } from "../../helpers";

interface FetchProfileCurrencyParams {
  profileId: number;
}

export const fetchProfileCurrency = async ({
  profileId,
}: FetchProfileCurrencyParams): Promise<ProfileCurrency> => {
  return makeBackendGetCallWithJsonResponse<ProfileCurrency>(
    LOAD_PROFILE_CURRENCY,
    `?profile_id=${profileId}`,
  ).then(promiseEngineEarsHandler);
};
