import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, useTheme } from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetSubscriptionStatus } from "../../../hooks/useGetSubscriptionStatus";
import {
  MAXIMUM_DEPOSIT,
  MINIMUM_DEPOSIT,
} from "../../../hooks/useManageEngineeringService";
import { useMinAndMaxServiceRates } from "../../../hooks/useMinAndMaxServiceRates";
import { SCREENS } from "../../../routes/screens";
import { getCalendarIntegrationStatus } from "../../../store/actions/calendarService";
import { createRecordingServiceParams } from "../../../store/actions/recording";
import { updateBookingStatus } from "../../../store/actions/studio";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import { Discounts } from "../../../store/models/recording";
import { StudioRoom } from "../../../store/models/studio";
import { SUBSCRIPTION_PLAN } from "../../../store/models/subscription";
import { selectIsUserRecordingEnabled } from "../../../store/selectors/userInfoSelectors";
import {
  DollarFormatter,
  PennyDollarFormatter,
} from "../../../store/utils/formatUtils";
import { getAddSubscriptionRoute } from "../../../store/utils/routeGetters";
import {
  convertHoursToMinutes,
  convertMinutesToHourOptionType,
  getHourOptionsWithMinimumDuration,
  hourOptions,
  reversedHourOptions,
} from "../../../store/utils/serviceUtils";
import { BaseAccordion } from "../../core-ui/components/BaseAccordion/BaseAccordion";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { PopConfirm } from "../../core-ui/components/PopConfirm/PopConfirm";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import { EditableTextArea } from "../../elements/EditableTextArea/EditableTextArea";
import { FixedRangeNumberField } from "../../elements/FixedRangeNumberField/FixedRangeNumberField";
import { PercentageTextfield } from "../../elements/PercentageTextField/PercentageTextfield";
import { PriceField } from "../../elements/PriceField/PriceField";
import { ToggleSwitch } from "../../elements/ToggleSwitch/ToggleSwitch";
import { CalendarManageView } from "../CalendarManageView/CalendarManageView";
import { CollapsableComponent } from "../CollapsableComponent/CollapsableComponent";
import { LocationInput } from "../LocationInput/LocationInput";
import "../ManageEngineeringService/ManageEngineeringService.css";
import {
  BlockDiscountContainer,
  PlatinumUpgradeContainer,
  ServiceFormColumn,
  ServiceFormInputContainer,
  ServiceFormLabel,
  ServiceFormLabelContainer,
  ServiceFormLabelSubtext,
  ServiceFormRow,
  ServiceRateContainer,
  ServiceRateFeeContainer,
} from "../ManageEngineeringService/ManageEngineeringService.styles";
import { ToolTipTextArea } from "../ToolTipTextArea/ToolTipTextArea";
import {
  HighlightedTextContainer,
  TravelToArtistRateContainer,
} from "./AddRecordingService.styles";
import { AddBlockDiscountButton } from "./components/AddBlockDiscountButton/AddBlockDiscountButton";

interface AddEngineeringServiceProps {
  studioRoom?: StudioRoom;
  studioId?: number;
  canManageStudio?: boolean;
  hideGoogleCalendar?: boolean;
  onUpdatePrice?: (newPrice: number) => void;
  discountRates: DiscountRateService[];
  setDiscountRate: Dispatch<SetStateAction<DiscountRateService[]>>;
  pendingRecordingService: createRecordingServiceParams;
  setPendingRecordingService: Dispatch<
    SetStateAction<createRecordingServiceParams>
  >;
  recordingLocation: google.maps.places.PlaceResult | undefined;
  setRecordingLocation: Dispatch<
    SetStateAction<google.maps.places.PlaceResult | undefined>
  >;
  isEditingRecordingService: boolean;
  handleDeleteClick: (serviceId: number, serviceType: ProjectType) => void;
  closeModal?: () => void;
  engineerId?: number;
}

export interface DiscountRateService {
  minTimeSelected: OptionType;
  discount: Discounts;
}

const MIN_TRAVEL_DISTANCE = 0;
const MAX_TRAVEL_DISTANCE = 100;

export const AddRecordingService: React.FC<AddEngineeringServiceProps> = ({
  studioRoom,
  studioId,
  canManageStudio,
  hideGoogleCalendar,
  onUpdatePrice,
  discountRates,
  setDiscountRate,
  pendingRecordingService,
  setPendingRecordingService,
  recordingLocation,
  setRecordingLocation,
  isEditingRecordingService,
  handleDeleteClick,
  closeModal,
  engineerId,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isRecordingEnabled: boolean = useAppSelector(
    selectIsUserRecordingEnabled,
  );
  const [acceptingBookings, setBooking] = useState<boolean>(true);
  const engineerRecordingService = useAppSelector(
    (state) => state.engineerServices.recordingService,
  );
  const recordingService = useMemo(() => {
    if (studioRoom) {
      return studioRoom.recording_service;
    }
    return engineerRecordingService;
  }, [engineerRecordingService, studioRoom]);

  const [allowPartialPayments, setAllowPartialPayments] = useState<boolean>(
    recordingService?.minimum_deposit_percentage
      ? recordingService.minimum_deposit_percentage < 1
      : false,
  );

  const [minServiceRate, maxServiceRate] = useMinAndMaxServiceRates(
    ProjectType.RECORDING,
  );

  const minimumSessionTimeOption = useMemo(() => {
    return pendingRecordingService.minimum_session_time_minutes
      ? convertMinutesToHourOptionType(
          pendingRecordingService.minimum_session_time_minutes,
        )
      : hourOptions[0];
  }, [pendingRecordingService.minimum_session_time_minutes]);

  const maximumSessionTimeOption = useMemo(() => {
    return pendingRecordingService.maximum_session_time_minutes
      ? convertMinutesToHourOptionType(
          pendingRecordingService.maximum_session_time_minutes,
        )
      : reversedHourOptions[0];
  }, [pendingRecordingService.maximum_session_time_minutes]);

  const [showDiscount, setShowDiscount] = useState<boolean>(
    discountRates.length > 0,
  );

  const discountHourOptions = getHourOptionsWithMinimumDuration(
    minimumSessionTimeOption.value,
    maximumSessionTimeOption.value,
  );

  const { calendarIntegrated } = useAppSelector(
    (state) => state.calendarService,
  );

  useEffect(() => {
    dispatch(getCalendarIntegrationStatus({ studio_id: studioId }))
      .then(() => {})
      .catch(() => {});
  }, [studioId, dispatch]);

  useEffect(() => {
    setDiscountRate((prev) => {
      return prev.filter(
        (discountRate) =>
          discountRate.discount.price < pendingRecordingService.price,
      );
    });
  }, [pendingRecordingService.price, setDiscountRate]);

  useEffect(() => {
    setDiscountRate((prev) => {
      return prev.filter(
        (discountRate) =>
          discountRate.minTimeSelected.value >= minimumSessionTimeOption.value,
      );
    });
  }, [minimumSessionTimeOption.value, setDiscountRate]);

  useEffect(() => {
    setDiscountRate((prev) => {
      return prev.filter(
        (discountRate) =>
          discountRate.minTimeSelected.value <= maximumSessionTimeOption.value,
      );
    });
  }, [maximumSessionTimeOption.value, setDiscountRate]);

  useEffect(() => {
    if (discountRates.length === 0) {
      setShowDiscount(false);
    }
  }, [discountRates]);

  const removeDiscountRate = (index: number) => {
    if (index < 0 || index >= discountRates.length) {
      return;
    }
    const newDiscountRate = [...discountRates];
    newDiscountRate.splice(index, 1);
    setDiscountRate(newDiscountRate);
  };

  const setPriceForDiscountRate = (
    minTimeToEnableRate: number,
    value: number,
    index: number,
  ) => {
    let hourlyRate: number = value / (minTimeToEnableRate / 60);
    if (index < 0 || index >= discountRates.length) {
      return;
    }

    if (hourlyRate > pendingRecordingService.price) {
      hourlyRate = +pendingRecordingService.price;
    }
    const discountRate = discountRates[index];
    discountRate.discount.price = +hourlyRate.toFixed(2);
    const newDiscountRate = [...discountRates];
    newDiscountRate[index] = discountRate;
    setDiscountRate(newDiscountRate);
  };

  const setMinimumTimeForDiscountRate = (
    selected: OptionType,
    index: number,
  ) => {
    if (
      discountRates.some(
        (discountRate) => discountRate.minTimeSelected.label === selected.label,
      )
    ) {
      return;
    }
    if (index < 0 || index >= discountRates.length) {
      return;
    }
    const selectedSessionInMinutes = convertHoursToMinutes(selected.value);
    if (
      selectedSessionInMinutes <
      convertHoursToMinutes(minimumSessionTimeOption.value)
    ) {
      return;
    }
    if (
      selectedSessionInMinutes >
      convertHoursToMinutes(maximumSessionTimeOption.value)
    ) {
      return;
    }
    const discountRate = discountRates[index];
    discountRate.minTimeSelected = selected;
    discountRate.discount.minimum_time_to_enable_rate = convertHoursToMinutes(
      selected.value,
    );
    const newDiscountRates = [...discountRates];
    newDiscountRates[index] = discountRate;
    setDiscountRate([...newDiscountRates]);
  };

  const changeUnitNumber = useCallback((value: string) => {
    setPendingRecordingService((prevState) => ({
      ...prevState,
      unit_number: value,
    }));
  }, []);

  const changeServiceRatePrice = useCallback((updatedPrice: number) => {
    setPendingRecordingService((prevState) => ({
      ...prevState,
      price: updatedPrice,
    }));
    onUpdatePrice?.(updatedPrice);
  }, []);

  const changeTravelToArtistRatePrice = useCallback((updatedPrice: number) => {
    setPendingRecordingService((prevState) => ({
      ...prevState,
      travel_to_artist_price: updatedPrice,
    }));
  }, []);

  useEffect(() => {
    if (!studioRoom?.recording_service) {
      return;
    }
    setBooking(!studioRoom?.recording_service?.not_accepting_bookings);
  }, [studioRoom]);

  useEffect(() => {
    if (!allowPartialPayments) {
      handleSettingDepositPercentage(1);
    }
  }, [allowPartialPayments]);

  const handleSettingDepositPercentage = (newValue: number) => {
    setPendingRecordingService((curr) => {
      return {
        ...curr,
        minimum_deposit_percentage: newValue.toString(),
      };
    });
  };

  const minimumDepositDescription = useMemo(() => {
    if (!pendingRecordingService.price) {
      return "Set service price";
    }
    if (!pendingRecordingService.minimum_deposit_percentage) {
      return "Set minimum deposit percentage";
    }
    return `${PennyDollarFormatter(pendingRecordingService.currency).format(+pendingRecordingService.minimum_deposit_percentage * pendingRecordingService.price)}`;
  }, [pendingRecordingService]);

  const userData = useAppSelector((state) => state.accountInfo.user);
  const { data: subscription } = useGetSubscriptionStatus({
    user: userData,
    refetchSubsStatus: true,
    force: true,
  });
  const showUpgradeButton = useMemo(() => {
    return (
      subscription?.stripe_subscription_status &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.PLATINUM_PLAN_MONTHLY &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.PLATINUM_PLAN_QUARTERLY &&
      subscription?.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY
    );
  }, [subscription]);

  const travelRate = useMemo(() => {
    const basePrice = Number(pendingRecordingService.price);
    const travelPrice = pendingRecordingService.travel_to_artist_price
      ? Number(pendingRecordingService.travel_to_artist_price)
      : 0;
    return PennyDollarFormatter(pendingRecordingService.currency).format(
      basePrice + travelPrice,
    );
  }, [
    pendingRecordingService.currency,
    pendingRecordingService.price,
    pendingRecordingService.travel_to_artist_price,
  ]);

  if (!isRecordingEnabled && !canManageStudio && !recordingService) {
    return (
      <div>
        <p>
          Recording is in beta. Please reach out to the EngineEars team if you
          would like to host Recording services.
        </p>
      </div>
    );
  }

  if (
    !calendarIntegrated ||
    (!studioRoom &&
      !pendingRecordingService.recording_location &&
      !recordingLocation)
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "32px",
          width: "100%",
        }}
      >
        <CalendarManageView studioID={studioId} />
        {!studioRoom && (
          <Box
            sx={{ display: "flex", flexDirection: "column", rowGap: "32px" }}
          >
            <ServiceFormColumn>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <ServiceFormLabel>My Location:</ServiceFormLabel>
                <ServiceFormLabelSubtext>
                  Your exact address will not be publicly shared. Only your city
                  and zip/postal code will be searchable to potential clients.
                </ServiceFormLabelSubtext>
              </div>
              <LocationInput
                defaultValue={
                  (pendingRecordingService.recording_location as string) ||
                  recordingLocation?.formatted_address
                }
                onPlaceSelected={(place) => {
                  setRecordingLocation(place);
                }}
                onClearValue={() => {
                  toast.error("Location cannot be blank!");
                  setPendingRecordingService((pendingService) => ({
                    ...pendingService,
                    recording_location: undefined,
                  }));
                }}
                showBorder
              />
            </ServiceFormColumn>
            <HighlightedTextContainer>
              <Text
                variant={TextStyleVariant.P2}
                bold
                color={TextColor.PRIMARY_NO_DARK_THEME}
              >
                You can update your location at any time in{" "}
                {recordingService ? (
                  <Link
                    style={{
                      fontWeight: "bold",
                      display: "inline-block",
                      minHeight: "auto",
                    }}
                    to={SCREENS.SETTINGS_SCREEN}
                  >
                    Account Settings.
                  </Link>
                ) : (
                  <span>Account Settings.</span>
                )}
              </Text>
            </HighlightedTextContainer>
          </Box>
        )}
      </Box>
    );
  }

  const onToggleBooking = async (checkState: boolean) => {
    if (!studioRoom?.recording_service) return;
    try {
      const isNotAcceptingBookings = !checkState;
      await dispatch(
        updateBookingStatus({
          studioRoomId: studioRoom.id,
          notAcceptingBookings: isNotAcceptingBookings,
        }),
      )
        .unwrap()
        .then(() => {
          toast.success(
            isNotAcceptingBookings
              ? `Bookings Disabled. Your studio room no longer allows bookings for ${studioRoom.room_name}`
              : `Bookings Enabled. Your studio now allows bookings for ${studioRoom.room_name}`,
          );
        });
    } finally {
      setBooking(checkState);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: "32px",
        width: "100%",
      }}
    >
      {studioRoom?.recording_service && (
        <div className={"add-engineer-service-form-row"}>
          <div className="add-engineer-service-form-row-label">
            <p>Accepting Bookings?</p>
          </div>
          <div
            className="add-engineer-service-form-row-input alt-percentage-slider"
            style={{ width: "100%" }}
          >
            <ToggleSwitch
              id="accepting-bookings-toggle"
              checked={acceptingBookings}
              onChange={onToggleBooking}
              label={acceptingBookings ? "Currently Booking" : "Not Booking"}
              updateCheckedLocally={false}
            />
          </div>
        </div>
      )}
      <ServiceRateContainer>
        <ServiceFormRow>
          <ServiceFormLabelContainer>
            <ServiceFormLabel>
              Hourly rate for your recording services
            </ServiceFormLabel>
            <ServiceFormLabelSubtext>
              (Does not include the cost of booking a studio, if applicable)
            </ServiceFormLabelSubtext>
          </ServiceFormLabelContainer>
          <ServiceFormInputContainer>
            <PriceField
              min={minServiceRate}
              max={maxServiceRate}
              currency={pendingRecordingService.currency}
              // The initial data received from the API is string, so we need to parse here
              value={parseInt(`${pendingRecordingService.price}`)}
              placeholder="Service Rate"
              onChange={changeServiceRatePrice}
            />
          </ServiceFormInputContainer>
        </ServiceFormRow>
        {showUpgradeButton && (
          <>
            <ServiceRateFeeContainer>
              <Text variant={TextStyleVariant.P3} weight={TEXT_WEIGHT.BOLD}>
                Service fee rate (10%)
              </Text>
              <Text variant={TextStyleVariant.P3} weight={TEXT_WEIGHT.BOLD}>
                {PennyDollarFormatter(pendingRecordingService.currency).format(
                  parseInt(`${pendingRecordingService.price}`) * 0.1,
                )}
              </Text>
            </ServiceRateFeeContainer>
            <PlatinumUpgradeContainer>
              <Text
                variant={TextStyleVariant.P1}
                weight={TEXT_WEIGHT.BOLD}
                style={{ color: theme.palette.standardColor.DeepBlue[800] }}
              >
                Reduce your service fee rate to 0% by upgrading to Platinum!
              </Text>
              <Link
                to={getAddSubscriptionRoute()}
                style={{ textDecoration: "none" }}
              >
                <Text
                  variant={TextStyleVariant.P3}
                  style={{
                    display: "inline",
                    color: theme.palette.standardColor.DeepBlue[800],
                    textDecoration: "underline",
                  }}
                >
                  learn more
                </Text>
              </Link>
            </PlatinumUpgradeContainer>
          </>
        )}
      </ServiceRateContainer>

      {!studioId && (
        <>
          <ServiceRateContainer>
            <ServiceFormRow>
              <ServiceFormLabelContainer>
                <Box>
                  <ServiceFormLabel>
                    I am willing to travel to the artist for sessions
                  </ServiceFormLabel>
                </Box>
              </ServiceFormLabelContainer>
              <ServiceFormInputContainer>
                <ToggleSwitch
                  checked={pendingRecordingService.will_come_to_you}
                  onChange={(value) => {
                    setPendingRecordingService((currentRecordingService) => ({
                      ...currentRecordingService,
                      will_come_to_you: value,
                      travel_to_artist_price: value ? minServiceRate : 0,
                      max_travel_distance_minutes: value
                        ? pendingRecordingService.max_travel_distance_minutes ??
                          0
                        : 0,
                    }));
                  }}
                />
              </ServiceFormInputContainer>
            </ServiceFormRow>
            {pendingRecordingService.will_come_to_you && (
              <TravelToArtistRateContainer>
                <Text bold variant={TextStyleVariant.P3}>
                  Total hourly rate + travel rate:
                </Text>
                <Text bold variant={TextStyleVariant.P3}>
                  {DollarFormatter(pendingRecordingService.currency).format(
                    pendingRecordingService.travel_to_artist_price ??
                      pendingRecordingService.price ??
                      0,
                  ) + "/hour"}
                </Text>
              </TravelToArtistRateContainer>
            )}
          </ServiceRateContainer>
        </>
      )}

      {pendingRecordingService.will_come_to_you && (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Text style={{ marginBottom: "16px" }} weight={TEXT_WEIGHT.SEMI_BOLD}>
            For sessions outside your affiliated studios:
          </Text>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "32px",
            }}
          >
            <ServiceFormRow>
              <ServiceFormLabelContainer>
                <ServiceFormLabel>
                  Travel to artist service rate <strong>(per hour)</strong>
                </ServiceFormLabel>
              </ServiceFormLabelContainer>
              <ServiceFormInputContainer>
                <PriceField
                  min={minServiceRate}
                  max={maxServiceRate}
                  currency={pendingRecordingService.currency}
                  value={Number(pendingRecordingService.travel_to_artist_price)}
                  placeholder="Travel To Artist Rate"
                  onChange={changeTravelToArtistRatePrice}
                />
              </ServiceFormInputContainer>
            </ServiceFormRow>
            <ServiceFormRow>
              <ServiceFormLabelContainer>
                <ServiceFormLabel>
                  How far are you willing to travel? <strong>(minutes)</strong>
                </ServiceFormLabel>
              </ServiceFormLabelContainer>
              <ServiceFormInputContainer>
                <FixedRangeNumberField
                  min={MIN_TRAVEL_DISTANCE}
                  max={MAX_TRAVEL_DISTANCE}
                  initialValue={
                    pendingRecordingService.max_travel_distance_minutes
                  }
                  onChange={(value: number) => {
                    setPendingRecordingService((prevState) => ({
                      ...prevState,
                      max_travel_distance_minutes: value,
                    }));
                  }}
                />
              </ServiceFormInputContainer>
            </ServiceFormRow>
          </Box>
        </Box>
      )}

      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel>
            {studioRoom
              ? "How much lead time is required for bookings in this room?"
              : "How much advance notice do you need before being booked?"}
            <strong>&nbsp;(minimum hours)</strong>
          </ServiceFormLabel>
          <ServiceFormLabelSubtext>
            Artists/clients who book less than 24 hours in advance are not
            entitled to a full refund if canceled.
          </ServiceFormLabelSubtext>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <FixedRangeNumberField
            min={0}
            max={24}
            initialValue={pendingRecordingService.number_of_hours_notice}
            placeholder="Hours"
            onChange={(value) => {
              setPendingRecordingService((prevState) => ({
                ...prevState,
                number_of_hours_notice: value,
              }));
            }}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel>
            How much time do you need between bookings?{" "}
            <strong>&nbsp;(mins)</strong>
          </ServiceFormLabel>
          <ToolTipTextArea text="Your average buffer time needed. E.g., meal breaks, studio room cleaning, travel time to artists, etc." />
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <FixedRangeNumberField
            min={0}
            max={120}
            initialValue={
              pendingRecordingService.minimum_gap_between_sessions_in_minutes
            }
            placeholder="Minutes"
            onChange={(value) => {
              setPendingRecordingService((prevState) => ({
                ...prevState,
                minimum_gap_between_sessions_in_minutes: value,
              }));
            }}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel>Minimum Session Time</ServiceFormLabel>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <DropdownSelector
            value={minimumSessionTimeOption}
            options={hourOptions}
            placeholder={"Minimum Session Time"}
            onChange={(selected) => {
              if (selected.value > maximumSessionTimeOption.value) {
                setPendingRecordingService((prevState) => ({
                  ...prevState,
                  minimum_session_time_minutes: convertHoursToMinutes(
                    selected.value,
                  ),
                  maximum_session_time_minutes: convertHoursToMinutes(
                    selected.value,
                  ),
                }));
              } else {
                setPendingRecordingService((prevState) => ({
                  ...prevState,
                  minimum_session_time_minutes: convertHoursToMinutes(
                    selected.value,
                  ),
                }));
              }
            }}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
      <ServiceFormRow>
        <ServiceFormLabelContainer>
          <ServiceFormLabel>Maximum Session Time</ServiceFormLabel>
        </ServiceFormLabelContainer>
        <ServiceFormInputContainer>
          <DropdownSelector
            value={maximumSessionTimeOption}
            options={reversedHourOptions}
            placeholder={"Maximum Session Time "}
            onChange={(selected) => {
              if (selected.value < minimumSessionTimeOption.value) {
                setPendingRecordingService((prevState) => ({
                  ...prevState,
                  minimum_session_time_minutes: convertHoursToMinutes(
                    selected.value,
                  ),
                  maximum_session_time_minutes: convertHoursToMinutes(
                    selected.value,
                  ),
                }));
              } else {
                setPendingRecordingService((prevState) => ({
                  ...prevState,
                  maximum_session_time_minutes: convertHoursToMinutes(
                    selected.value,
                  ),
                }));
              }
            }}
          />
        </ServiceFormInputContainer>
      </ServiceFormRow>
      <BaseAccordion
        renderHeader={() => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text bold variant={TextStyleVariant.P2}>
              Advanced settings
            </Text>
          </Box>
        )}
        sx={{
          "&.MuiAccordion-root": {
            boxShadow: "none",
            borderBottom: "none",
            backgroundColor: "transparent",
            backgroundImage: "none",
          },
          "& .MuiAccordionSummary-root": {
            padding: "0px",
          },
          "&::before": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "32px",
            width: "100%",
          }}
        >
          <BlockDiscountContainer>
            <ServiceFormRow>
              <ServiceFormLabelContainer>
                <Box>
                  <ServiceFormLabel>Offer block discounts</ServiceFormLabel>
                </Box>
              </ServiceFormLabelContainer>
              <ServiceFormInputContainer>
                <ToggleSwitch
                  checked={showDiscount}
                  updateCheckedLocally={false}
                  onChange={(checkedState) => {
                    if (
                      discountRates.length === 0 &&
                      discountHourOptions.length
                    ) {
                      const discountAddSessionTime =
                        discountHourOptions[0].value * 60;
                      const optionSelected = {
                        minTimeSelected: discountHourOptions[0],
                        discount: {
                          minimum_time_to_enable_rate: discountAddSessionTime,
                          price: pendingRecordingService.price,
                        },
                      };
                      setDiscountRate([optionSelected]);
                    }
                    setShowDiscount(checkedState);
                    if (!checkedState) {
                      setDiscountRate([]);
                    }
                  }}
                />
              </ServiceFormInputContainer>
            </ServiceFormRow>
            {discountRates.length > 0 && (
              <div className="discount-options-container">
                {showDiscount &&
                  discountRates.map((discountRate, index) => {
                    let discountPercentage = 0;
                    if (
                      pendingRecordingService.price &&
                      discountRate.discount.price
                    ) {
                      discountPercentage =
                        ((pendingRecordingService.price -
                          discountRate.discount.price) /
                          pendingRecordingService.price) *
                        100;
                    }

                    return (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          columnGap: "12px",
                          rowGap: "12px",
                          flexWrap: "wrap",

                          ".drop-down-selector-container": {
                            width: "120px",
                          },
                        }}
                        key={index}
                      >
                        <Button
                          variant={ButtonVariant.ICON}
                          onClick={() => removeDiscountRate(index)}
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </Button>
                        <DropdownSelector
                          value={discountRate.minTimeSelected}
                          options={getHourOptionsWithMinimumDuration(
                            minimumSessionTimeOption
                              ? minimumSessionTimeOption.value
                              : 3,
                            maximumSessionTimeOption
                              ? maximumSessionTimeOption.value
                              : 24,
                          )}
                          placeholder={"Minimum Session Time "}
                          onChange={(selected) =>
                            setMinimumTimeForDiscountRate(selected, index)
                          }
                        />
                        <Text>for</Text>
                        <PriceField
                          min={
                            minServiceRate *
                            (discountRate.discount.minimum_time_to_enable_rate /
                              60)
                          }
                          max={
                            pendingRecordingService.price *
                            discountRate.minTimeSelected.value
                          }
                          value={
                            (discountRate.discount.price *
                              discountRate.discount
                                .minimum_time_to_enable_rate) /
                            60
                          }
                          currency={pendingRecordingService.currency}
                          placeholder="Service Rate"
                          className="discount-options-fixed-range-number-field"
                          onChange={(value) => {
                            setPriceForDiscountRate(
                              discountRate.discount.minimum_time_to_enable_rate,
                              value,
                              index,
                            );
                          }}
                          style={{ width: "95px" }}
                        />
                        <Text>
                          total ({discountPercentage.toFixed(2)}% off).
                        </Text>
                      </Box>
                    );
                  })}
              </div>
            )}
            {(showDiscount || discountRates.length > 0) && (
              <AddBlockDiscountButton
                discountRates={discountRates}
                minimumSessionTimeValue={minimumSessionTimeOption.value}
                maximumSessionTimeValue={maximumSessionTimeOption.value}
                pendingServicePrice={pendingRecordingService.price}
                setDiscountRate={setDiscountRate}
                setShowDiscount={setShowDiscount}
              />
            )}
          </BlockDiscountContainer>
          <ServiceFormRow>
            <ServiceFormLabelContainer>
              <Box
                sx={{ display: "flex", alignItems: "center", columnGap: "4px" }}
              >
                <ServiceFormLabel>
                  Allow partial payments&nbsp;
                </ServiceFormLabel>
                <ToolTipTextArea
                  text={
                    "Allows your clients to put down an initial deposit when booking; this percentage determines the initial deposit amount."
                  }
                />
              </Box>
            </ServiceFormLabelContainer>
            <ServiceFormInputContainer>
              <ToggleSwitch
                checked={allowPartialPayments}
                onChange={(checked: boolean) => {
                  setAllowPartialPayments(checked);
                }}
              />
            </ServiceFormInputContainer>
          </ServiceFormRow>
          {allowPartialPayments && (
            <ServiceFormRow>
              <ServiceFormLabelContainer>
                <ServiceFormLabel>Minimum deposit required</ServiceFormLabel>
                <ServiceFormLabelSubtext>
                  (Initial deposit amount {minimumDepositDescription} per hour)
                </ServiceFormLabelSubtext>
              </ServiceFormLabelContainer>
              <ServiceFormInputContainer>
                <PercentageTextfield
                  max={MAXIMUM_DEPOSIT}
                  min={MINIMUM_DEPOSIT}
                  value={
                    pendingRecordingService.minimum_deposit_percentage
                      ? parseFloat(
                          pendingRecordingService.minimum_deposit_percentage,
                        )
                      : 0
                  }
                  onChangePercentage={handleSettingDepositPercentage}
                  step={5}
                />
              </ServiceFormInputContainer>
            </ServiceFormRow>
          )}
        </Box>
      </BaseAccordion>

      {studioId && !studioRoom && (
        <ServiceFormColumn>
          <div>
            <ServiceFormLabel>Unit number (if applicable):</ServiceFormLabel>
          </div>
          <div>
            <EditableTextArea
              editMode={true}
              numberOfLines={1}
              width={"100%"}
              initialValue={pendingRecordingService.unit_number ?? ""}
              onChange={changeUnitNumber}
              placeholder={"Enter unit number (if applicable)"}
            />
          </div>
        </ServiceFormColumn>
      )}

      {studioId && !studioRoom && (
        <ServiceFormColumn>
          <div>
            <p>Transit, parking, and access information:</p>
          </div>
          <div>
            <EditableTextArea
              editMode={true}
              width={"100%"}
              initialValue={pendingRecordingService.arrival_information}
              onChange={(value) => {
                setPendingRecordingService((prevState) => ({
                  ...prevState,
                  arrival_information: value,
                }));
              }}
              placeholder={"Enter information for the client"}
            />
          </div>
        </ServiceFormColumn>
      )}

      {!hideGoogleCalendar && calendarIntegrated && (
        <CollapsableComponent hintText={"Google Calendar Integration"}>
          <ServiceFormColumn sx={{ marginTop: "8px", marginBottom: "16px" }}>
            <CalendarManageView
              showExplanation={true}
              engineerId={engineerId}
              onRemoveGoogleCalendarIntegration={closeModal}
            />
          </ServiceFormColumn>
          <HighlightedTextContainer>
            <Text
              variant={TextStyleVariant.P2}
              bold
              color={TextColor.PRIMARY_NO_DARK_THEME}
            >
              You can also link your Google Calendar at any time in{" "}
              {recordingService ? (
                <Link
                  style={{
                    fontWeight: "bold",
                    display: "inline-block",
                    minHeight: "auto",
                  }}
                  to={SCREENS.SETTINGS_SCREEN}
                >
                  Account Settings.
                </Link>
              ) : (
                <span>Account Settings.</span>
              )}
            </Text>
          </HighlightedTextContainer>
        </CollapsableComponent>
      )}
      {isEditingRecordingService && recordingService && (
        <ServiceFormRow>
          <PopConfirm
            title="Are you sure?"
            description="You are about to remove this service. Are you sure?"
            onConfirm={() => {
              handleDeleteClick(recordingService.id, ProjectType.RECORDING);
            }}
          >
            <Button
              variant={ButtonVariant.DELETE}
              style={{ marginLeft: "auto", minWidth: "140px" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <FontAwesomeIcon icon={faTrashCan} />
                Delete this service
              </Box>
            </Button>
          </PopConfirm>
        </ServiceFormRow>
      )}
    </Box>
  );
};
