import {
  faBackwardFast,
  faBackwardStep,
  faForwardFast,
  faForwardStep,
  faPause,
  faPlay,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCirclePause,
  faCirclePlay,
  faListMusic,
  faRepeat,
} from "@fortawesome/sharp-solid-svg-icons";
import { SyntheticEvent } from "react";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  PlaybackControlButtonSize,
  StyledPlaybackControlButtonFontAwesomeIcon,
  StyledPlaybackControlButtonRepeatActiveIcon,
} from "./PlaybackControlButton.styles";
import classNames from "classnames";

export enum PlaybackControlButtonVariant {
  PLAY,
  PLAY_CIRCLE,
  PAUSE,
  PAUSE_CIRCLE,
  FORWARD_STEP,
  FORWARD_FAST,
  BACKWARD_STEP,
  BACKWARD_FAST,
  REPEAT,
  PLAYLIST,
}

export interface PlaybackControlButtonProps {
  variant: PlaybackControlButtonVariant;
  onClick: () => void;
  disabled?: boolean;
  size?: PlaybackControlButtonSize;
  isPrimary?: boolean;
  isToggled?: boolean;
  style?: React.CSSProperties;
  hidden?: boolean;
}

const PlaybackControlIcon: Record<
  PlaybackControlButtonVariant,
  IconDefinition
> = {
  [PlaybackControlButtonVariant.PLAY]: faPlay,
  [PlaybackControlButtonVariant.PLAY_CIRCLE]: faCirclePlay,
  [PlaybackControlButtonVariant.PAUSE]: faPause,
  [PlaybackControlButtonVariant.FORWARD_STEP]: faForwardStep,
  [PlaybackControlButtonVariant.FORWARD_FAST]: faForwardFast,
  [PlaybackControlButtonVariant.BACKWARD_STEP]: faBackwardStep,
  [PlaybackControlButtonVariant.BACKWARD_FAST]: faBackwardFast,
  [PlaybackControlButtonVariant.REPEAT]: faRepeat,
  [PlaybackControlButtonVariant.PLAYLIST]: faListMusic,
  [PlaybackControlButtonVariant.PAUSE_CIRCLE]: faCirclePause,
};

export const PlaybackControlButton = ({
  variant,
  onClick,
  disabled = false,
  isPrimary = false,
  size = PlaybackControlButtonSize.REGULAR,
  isToggled = false,
  hidden = false,
  style,
}: PlaybackControlButtonProps) => {
  const onClickControlButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <Button
      disabled={disabled}
      variant={ButtonVariant.UNSTYLED}
      style={{
        minHeight: "unset",
        ...(hidden ? { visibility: "hidden", pointerEvents: "none" } : {}),
      }}
      onClick={onClickControlButton}
    >
      <div style={{ position: "relative" }}>
        <StyledPlaybackControlButtonFontAwesomeIcon
          className={classNames({
            primary: isPrimary,
            toggled: isToggled,
            disabled: disabled,
          })}
          icon={PlaybackControlIcon[variant]}
          size={size}
          style={style}
        />
        {PlaybackControlIcon[variant] === faRepeat && isToggled && (
          <StyledPlaybackControlButtonRepeatActiveIcon
            className={classNames({
              primary: isPrimary,
              toggled: isToggled,
              [size]: Boolean(size),
            })}
          />
        )}
      </div>
    </Button>
  );
};
