import Grid2 from "@mui/material/Grid2/Grid2";
import { ManagePaymentsSettings } from "../../ManagePaymentsSettings/ManagePaymentsSettings";
import { ManageSubscriptionCard } from "../../ManageSubscriptionCard/ManageSubscriptionCard";
import { ProfileCurrencySelection } from "./ProfileCurrencySelection";
import { useAtomValue } from "jotai";
import { activeProfileAtom } from "../../../../atoms/user/activeProfileAtom";

interface PaymentAndBillingProps {
  onClose: () => void;
}

export const PaymentAndBilling = ({ onClose }: PaymentAndBillingProps) => {
  const activeProfile = useAtomValue(activeProfileAtom);
  return (
    <Grid2 container spacing={4}>
      <Grid2 size={{ xs: 12, md: 6 }}>
        <ManagePaymentsSettings />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 6 }}>
        <ManageSubscriptionCard onClose={onClose} />
      </Grid2>
      {activeProfile && (
        <Grid2 size={{ xs: 12, md: 6 }}>
          <ProfileCurrencySelection activeProfile={activeProfile} />
        </Grid2>
      )}
    </Grid2>
  );
};
