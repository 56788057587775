import { styled } from "@mui/material";

export const UploaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  padding: "24px",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  border: `1px dashed ${theme.palette.divider}`,
  borderRadius: theme.border.radius.md,
  background: theme.palette.customColor.midgroundColor,
  cursor: "pointer",
  "&.disabled": {
    cursor: "no-drop",
  },
  [theme.breakpoints.up("md")]: {
    padding: "24px 100px",
  },
}));
