import { ColorPalette } from "../../stories/theme";

export enum BannerColor {
  NO_BANNER_CHOICE,
  DEEP_BLUE,
  DEEP_PINK,
  ECHO_ORANGE,
  DEEP_GREEN,
  BOXY_YELLOW,
  BOOMY_ORANGE,
  SKYLIGHT_BLUE,
  MINT_GREEN,
  MUDDY_BROWN,
}

export const BannerColorMap = new Map<string, BannerColor>([
  [ColorPalette.White, BannerColor.NO_BANNER_CHOICE],
  [ColorPalette.DeepBlue300, BannerColor.DEEP_BLUE],
  [ColorPalette.DeepPink, BannerColor.DEEP_PINK],
  [ColorPalette.EchoOrange, BannerColor.ECHO_ORANGE],
  [ColorPalette.DeepGreen, BannerColor.DEEP_GREEN],
  [ColorPalette.BoxyYellow, BannerColor.BOXY_YELLOW],
  [ColorPalette.BoomyOrange400, BannerColor.BOOMY_ORANGE],
  [ColorPalette.SkylightBlue, BannerColor.SKYLIGHT_BLUE],
  [ColorPalette.MintGreen500, BannerColor.MINT_GREEN],
  [ColorPalette.MuddyBrown, BannerColor.MUDDY_BROWN],
]);

interface ProfileTab {
  key: number;
  locked?: boolean | null;
  locked_reason?: string | null;
}

export default interface Profile {
  display_name: string;
  bio: string;
  long_bio: string;
  soundcloud_username: string;
  twitter_username: string;
  instagram_username: string;
  facebook_username: string;
  twitch_username: string;
  tiktok_username: string;
  youtube_username: string;
  youtube_livestream_id?: string;
  spotify_username: string;
  apple_username: string;
  tidal_username: string;
  amazon_username: string;
  location: string;
  country: string;
  region?: string;
  city: string;
  genres_string: string;
  birth_date: string;
  banner_color: BannerColor;
  tab_order_preferences: ProfileTab[];
}

export const MockProfile: Profile = {
  display_name: "Mock Display Name",
  bio:
    "NoName is an independent recording, mixing, and mastering studio that provides top-notch sound production services. With a team of experienced engineers and state-of-the-art equipment, NoName offers a comfortable and creative environment for artists to bring their musical visions to life. From recording to mixing and mastering, NoName provides a range of services tailored to the needs of each client. With a commitment to excellence and a passion for music, NoName strives to deliver exceptional results and exceed client expectations.\n" +
    "\n",
  long_bio:
    "NoName is an independent recording, mixing, and mastering studio that was founded with a vision to provide a high-quality and personalized approach to sound production. The studio is equipped with top-of-the-line equipment and software to ensure that every project is produced with the utmost attention to detail and creativity.\n" +
    "\n" +
    "NoName's team of experienced engineers has worked with a diverse range of artists and genres, and they bring their expertise to every project they undertake. The studio provides a comfortable and creative environment for artists to bring their musical visions to life. From recording to mixing and mastering, NoName offers a range of services tailored to the needs of each client, and they work closely with each artist to ensure that their unique sound is captured and brought to life.\n" +
    "\n" +
    "At NoName, the team is passionate about music, and they take pride in delivering exceptional results that exceed client expectations. Whether it's a full-length album, a single, or a commercial project, NoName is committed to providing the highest quality sound production services to help artists achieve their goals and bring their music to the world.",
  soundcloud_username: "Mock Soundcloud Username",
  twitter_username: "Mock Twitter Username",
  instagram_username: "Mock Instagram Username",
  facebook_username: "Mock Facebook Username",
  twitch_username: "Mock Twitch Username",
  tiktok_username: "Mock TikTok Username",
  youtube_username: "Mock Youtube Username",
  spotify_username: "Mock Spotify Username",
  apple_username: "Mock Apple Username",
  tidal_username: "Mock Tidal Username",
  amazon_username: "Mock Amazon Username",
  location: "Mock Location",
  country: "Mock Country",
  region: "Mock Region",
  city: "Mock City",
  genres_string: "Mock Genres String",
  birth_date: "Mock Birth Date",
  banner_color: BannerColorMap.get(ColorPalette.DeepBlue300)!,
  tab_order_preferences: [],
};

export interface EngineEarsCurrency {
  name: string;
  code: string;
}

export interface ProfileCurrency {
  id: number;
  currency: string;
  valid_currencies: Record<string, EngineEarsCurrency>;
}
