import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { Alt } from "../models/alts";
import { ProjectType } from "../models/project";
import { MAX_SONGS_PER_SCHEDULED_PROJECT } from "../utils/serviceUtils";
import { getItemizedTransaction, updateTransaction } from "./transactions";
import { getCartDiscountStatusAndValuesFromItemizedTransaction } from "../utils/cartUtils";

export enum PredefinedDiscountOptions {
  "10%" = "10%",
  "25%" = "25%",
  "50%" = "50%",
}

export const predefinedDiscountOptionValueMap = new Map<
  PredefinedDiscountOptions,
  number
>([
  [PredefinedDiscountOptions["10%"], 0.9],
  [PredefinedDiscountOptions["25%"], 0.75],
  [PredefinedDiscountOptions["50%"], 0.5],
]);

export const discountValueToLabelMap = new Map<
  number,
  PredefinedDiscountOptions
>([
  [-10, PredefinedDiscountOptions["10%"]],
  [-25, PredefinedDiscountOptions["25%"]],
  [-50, PredefinedDiscountOptions["50%"]],
]);

export interface AltOrderSummaryData {
  // will include MAIN alt
  altList: Alt[];
  altUnitPrice: number;
}

export interface MixMasterProjectOrderSummaryData {
  serviceType: ProjectType;
  quantity: number;
  currency?: string;
  updatedUnitPrice?: number;
  serviceUnitPrice: number;
  serviceTotalPrice: number;
  alts: AltOrderSummaryData;
}

export interface MixMasterOrderSummary {
  loading?: boolean;
  subtotal: number;
  currency?: string;
  fees: number;
  totalPrice: number;
  discountOrSurchargeValue: number;
  discountOrSurchargePercentage: number;
  projects: MixMasterProjectOrderSummaryData[];
}
export interface MixMasterCart {
  title: string;
  artistName: string;
  quantity: number;
  engineerHasFiles: boolean;
  inProgressProject: boolean;
  alts: Alt[];
  addMaster: boolean;
  addAtmos: boolean;
  applyLabelRate: boolean;
  overwritePrice: number | null;
  estimatedDeliveryDateData: {
    date: string;
    is_computed: boolean;
  } | null;
  promocode: string | null;
  providerAssumesFees: boolean;
  predefinedDiscountOption: PredefinedDiscountOptions | null;
}

export const defaultMixMasterCart: MixMasterCart = {
  title: "",
  artistName: "",
  quantity: 1,
  engineerHasFiles: false,
  inProgressProject: false,
  alts: [],
  addMaster: false,
  addAtmos: false,
  applyLabelRate: false,
  overwritePrice: null,
  estimatedDeliveryDateData: null,
  promocode: null,
  providerAssumesFees: false,
  predefinedDiscountOption: null,
};

interface MixMasterCartsState {
  orderSummary: MixMasterOrderSummary | null;
  isLoading: boolean;
  cart: MixMasterCart;
  inProgressProjectCreated: boolean;
  // Used to track if overwrite price has been applied to the transaction for UI state.
  currentOverwritePrice: number | null;
}

export const defaultMixMasterOrderSummary: MixMasterOrderSummary = {
  subtotal: 0,
  fees: 0,
  totalPrice: 0,
  discountOrSurchargePercentage: 0,
  discountOrSurchargeValue: 0,
  projects: [],
};

export const defaultAltOrderSummaryData: AltOrderSummaryData = {
  altList: [],
  altUnitPrice: 0,
};

export const defaultMixMasterProjectOrderSummaryData: MixMasterProjectOrderSummaryData =
  {
    serviceType: ProjectType.NO_TYPE,
    quantity: 0,
    serviceUnitPrice: 0,
    serviceTotalPrice: 0,
    alts: defaultAltOrderSummaryData,
  };

const initialState: MixMasterCartsState = {
  orderSummary: null,
  isLoading: false,
  cart: defaultMixMasterCart,
  inProgressProjectCreated: false,
  currentOverwritePrice: null,
};

const mixMasterCartsSlice = createSlice({
  name: "mixMasterCarts",
  initialState,
  reducers: {
    addMixMasterCart: (
      state,
      action: PayloadAction<MixMasterCart | undefined>,
    ) => {
      state.cart = action.payload ?? defaultMixMasterCart;
    },
    resetMixMasterCart: (state) => {
      state.cart = defaultMixMasterCart;
    },
    updateTitle: (state, action: PayloadAction<string>) => {
      state.cart.title = action.payload;
    },
    updateArtistName: (state, action: PayloadAction<string>) => {
      state.cart.artistName = action.payload;
    },
    setEstimatedDeliveryDate: (state, action: PayloadAction<string>) => {
      state.cart.estimatedDeliveryDateData = {
        date: action.payload,
        is_computed: false,
      };
    },
    toggleApplyLabelRate: (state) => {
      state.cart = {
        ...state.cart,
        applyLabelRate: !state.cart.applyLabelRate,
        overwritePrice: null,
      };
    },
    toggleMixMasterProviderAssumesFees: (state) => {
      state.cart = {
        ...state.cart,
        providerAssumesFees: !state.cart.providerAssumesFees,
      };
    },
    toggleEngineerHasFiles: (state) => {
      state.cart = {
        ...state.cart,
        engineerHasFiles: !state.cart.engineerHasFiles,
      };
    },
    resetAddOns: (state) => {
      state.cart = {
        ...state.cart,
        addAtmos: false,
        addMaster: false,
      };
    },
    toggleAddAtmos: (state) => {
      state.cart = {
        ...state.cart,
        addAtmos: !state.cart.addAtmos,
        overwritePrice: null,
      };
    },
    toggleAddMaster: (state) => {
      state.cart = {
        ...state.cart,
        addMaster: !state.cart.addMaster,
        overwritePrice: null,
      };
    },
    toggleInProgressProject: (state) => {
      state.cart = {
        ...state.cart,
        inProgressProject: !state.cart.inProgressProject,
      };
    },
    setQuantity: (state, action: PayloadAction<number>) => {
      state.cart = {
        ...state.cart,
        quantity: action.payload,
        overwritePrice: null,
      };
    },
    incrementQuantity: (state) => {
      state.cart = {
        ...state.cart,
        quantity: Math.min(
          MAX_SONGS_PER_SCHEDULED_PROJECT,
          state.cart.quantity + 1,
        ),
        overwritePrice: null,
      };
    },
    decrementQuantity: (state) => {
      state.cart = {
        ...state.cart,
        quantity: Math.max(1, state.cart.quantity - 1),
        overwritePrice: null,
      };
    },
    clearAlts: (state) => {
      state.cart.alts = [];
      state.cart.overwritePrice = null;
    },
    selectAlt: (state, action: PayloadAction<Alt>) => {
      // Using includes isn't a performance issue here.
      // The max length of the alts array will be < 10.
      if (!state.cart.alts.includes(action.payload)) {
        state.cart = {
          ...state.cart,
          alts: [...state.cart.alts, action.payload],
          overwritePrice: null,
        };
      }
    },
    unselectAlt: (state, action: PayloadAction<Alt>) => {
      state.cart = {
        ...state.cart,
        alts: state.cart.alts.filter((alt) => alt !== action.payload),
        overwritePrice: null,
      };
    },
    setMixMasterOverwritePrice: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.cart = {
        ...state.cart,
        overwritePrice: action.payload,
        predefinedDiscountOption: null,
      };
    },
    setIsMixMasterOrderSummaryLoading: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isLoading = action.payload;
    },
    addMixMasterPromoCode: (state, action: PayloadAction<string>) => {
      state.cart.promocode = action.payload;
      state.cart.overwritePrice = null;
    },
    updateMixMasterOrderSummary: (
      state,
      action: PayloadAction<MixMasterOrderSummary | null>,
    ) => {
      state.orderSummary = action.payload;
    },
    setMixMasterPredefinedDiscountOption: (
      state,
      action: PayloadAction<PredefinedDiscountOptions | null>,
    ) => {
      state.cart.predefinedDiscountOption = action.payload;
      state.cart.overwritePrice = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateTransaction.fulfilled, (state, action) => {
      if (
        !_.isEqual(
          state.cart.estimatedDeliveryDateData,
          action.payload.estimated_delivery_date_data,
        )
      ) {
        state.cart.estimatedDeliveryDateData =
          action.payload.estimated_delivery_date_data;
      }
    });
    builder.addCase(getItemizedTransaction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getItemizedTransaction.rejected, (state) => {
      state.isLoading = false;
      state.currentOverwritePrice = null;
      state.cart.overwritePrice = null;
    });
    builder.addCase(getItemizedTransaction.fulfilled, (state, action) => {
      const projects: MixMasterProjectOrderSummaryData[] =
        action.payload.engineering_service_items.map((item) => ({
          serviceType: item.service_type,
          quantity: item.quantity,
          currency: action.payload.currency,
          updatedUnitPrice: item.updated_unit_price,
          serviceUnitPrice: item.unit_price,
          serviceTotalPrice:
            (item.updated_unit_price ?? item.unit_price) * item.quantity,
          alts: {
            altList: item.alts?.info ?? [],
            altUnitPrice: item.alts?.unit_price ?? 0,
          },
        }));

      if (projects.length > 0) {
        state.orderSummary = {
          currency: action.payload.currency,
          fees: action.payload.fees,
          subtotal: action.payload.subtotal,
          totalPrice: action.payload.total,
          discountOrSurchargeValue: action.payload.discount_or_surcharge_value,
          discountOrSurchargePercentage:
            action.payload.discount_or_surcharge_percentage,
          projects,
        };
      } else {
        state.orderSummary = null;
      }

      // Following sets predefinedDiscountOption, overwritePrice and providerAssumesFees state on reload.
      // Will prioritize predefinedDiscountOption over overwritePrice if the values are equal.
      const { predefinedDiscountOption, providerAssumesFees } =
        getCartDiscountStatusAndValuesFromItemizedTransaction(action.payload);
      state.cart.predefinedDiscountOption = predefinedDiscountOption;
      state.cart.providerAssumesFees = providerAssumesFees;

      state.cart.overwritePrice = action.payload.overwrite_price;
      state.currentOverwritePrice = action.payload.overwrite_price;
      state.isLoading = false;
      state.inProgressProjectCreated = action.payload.in_progress_project;
    });
  },
});

export const {
  addMixMasterCart,
  toggleEngineerHasFiles,
  toggleAddAtmos,
  toggleAddMaster,
  resetAddOns,
  toggleInProgressProject,
  setQuantity,
  decrementQuantity,
  incrementQuantity,
  resetMixMasterCart,
  selectAlt,
  unselectAlt,
  clearAlts,
  updateTitle,
  updateArtistName,
  toggleApplyLabelRate,
  toggleMixMasterProviderAssumesFees,
  setMixMasterOverwritePrice,
  setEstimatedDeliveryDate,
  setIsMixMasterOrderSummaryLoading,
  addMixMasterPromoCode,
  updateMixMasterOrderSummary,
  setMixMasterPredefinedDiscountOption,
} = mixMasterCartsSlice.actions;
export default mixMasterCartsSlice.reducer;
