import { ScheduledProjectPaywallTypes } from "../../store/models/project";
import { ReleaseType } from "../../store/models/release";
import {
  FinancialMethod,
  TransactionStatus,
} from "../../store/models/transaction";

export interface ProductTransaction {
  id: number;
  currency?: string;
  original_price: string | null;
  user: number;
  transaction_status: TransactionStatus;
  financial_method: FinancialMethod;
  stripe_session_id: string | null;
  payment_id: string | null;
  code: string;
  executed: string | null;
}

export interface ProductTransactionResponse {
  transaction: ProductTransaction;
  requires_shipping_address: boolean;
  requires_clothing_size: boolean;
}

export interface ShoppingCartItem {
  product_id: number;
  release_track_ids: number[];
  use_total_price: boolean;
}

export interface ProductTransactionCreateArgs {
  shopping_cart_items: ShoppingCartItem[];
}

interface ProductTransactionItem {
  id: number;
  currency?: string;
  eventually_owed_to: number;
  product: number;
  release_track: number;
  amount: number;
  fees_collected: number;
  product_id: number;
  release_track_id: number;
  release_track_title: string;
  file_version_id: number;
  track_number: number;
  release_id: number;
}

export interface TransactionItemsGroupedByProduct {
  product_id: number;
  product_name: string;
  product_description: string;
  product_unit_price: string;
  product_total_price: string;
  product_owner_username: string;
  product_owner_display_name: string;
  release_type: ReleaseType;
  release_title: string | null;
  total_amount: string;
  total_fees: string;
  items: ProductTransactionItem[];
}

export interface PurchasedProductTransaction extends ProductTransaction {
  name: string | null;
  release_type: ReleaseType;
  artist_display_name: string;
  artist_username: string;
  release_date: string | null;
  release_id: number;
  release_title: string | null;
}

export interface PurchasedProductTransactionResponse {
  data: PurchasedProductTransaction[];
  current_page: number;
  total_pages: number;
  count: number;
  search_query: string;
  order_by: PurchasedProductTransactionOrderBy;
}

export enum PurchasedProductTransactionOrderBy {
  PURCHASE_DATE_DESC = "purchase_date_desc",
  PURCHASE_DATE_ASC = "purchase_date_asc",
  PRODUCT_NAME_ASC = "product_name_asc",
  PRODUCT_NAME_DESC = "product_name_desc",
  RELEASE_TITLE_ASC = "release_title_asc",
  RELEASE_TITLE_DESC = "release_title_desc",
  ARTIST_NAME_ASC = "artist_name_asc",
  ARTIST_NAME_DESC = "artist_name_desc",
  RELEASE_TYPE_ASC = "release_type_asc",
  RELEASE_TYPE_DESC = "release_type_desc",
  RELEASE_DATE_ASC = "release_date_asc",
  RELEASE_DATE_DESC = "release_date_desc",
}

export const generateBookingTitleMap: {
  [key in ScheduledProjectPaywallTypes]: string;
} = {
  [ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED]: "Pay to Start",
  [ScheduledProjectPaywallTypes.TRACK_PREVIEW_NOT_ALLOWED]: "Pay to Play",
  [ScheduledProjectPaywallTypes.TRACK_PREVIEW_ALLOWED]: "Pay to Review",
  [ScheduledProjectPaywallTypes.REVIEWS_AND_REVISIONS_ALLOWED]:
    "Pay to Download",
};
