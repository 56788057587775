import Service from "../store/models/service";
import { PennyDollarFormatter } from "../store/utils/formatUtils";

export const useMinimumDepositDescription = (service?: Service): string => {
  const getMinDeposit = (pendingService: Service): string => {
    if (!pendingService.service_rate?.price) {
      return "Set your service rate";
    }
    if (!pendingService.minimum_deposit_percentage) {
      return "Set deposit percentage";
    }
    return `${PennyDollarFormatter(pendingService.service_rate?.currency).format(pendingService.minimum_deposit_percentage * pendingService.service_rate?.price)}`;
  };
  if (!service) {
    return "";
  }
  return getMinDeposit(service);
};
