import {
  availability_multiplier,
  FirstPassDate,
} from "../../stories/components/ProjectBookingCalendar/ProjectBookingCalendar";
import {
  getCurrentDate,
  getFormattedDate,
  getNDaysFromNow,
  TIME_IN_A_DAY_MS,
} from "../utils/dateTimeUtils";
import { AltItem } from "./alts";
import {
  MockMasteringProject,
  MockMixingProject,
} from "./mockDataForMixMaster/mockDataForMixMaster";
import {
  APIMasteringProject,
  APIMixingProject,
  MasteringProject,
  MixingProject,
  Project,
  ProjectType,
  ScheduledProjectPaywallTypes,
  transformRawData,
} from "./project";
import { RecordingSession } from "./recordingSession";
import User, { MockUser, MockUser1, UserLite } from "./user";

export const BASE_RATE_MULTIPLIER = 1.0;
export const FULL_ALBUM_LENGTH = 12;
export const ALBUM_DISCOUNT = 0.7;
export const EP_LENGTH = 6;
export const EP_DISCOUNT = 0.85;

export const DEFAULT_DAYS_TO_DISPLAY = 7;
export const EXPANDED_DAYS_TO_DISPLAY = 28;

export interface ProjectAcceptance {
  user_id: number | null;
  accepted: string | null;
  deleted: string | null;
}

export interface ScheduledRevision {
  project_id: number;
  revision_number: number;
  revision_date: string | null;
}

export interface MinimalScheduledProject {
  id: number;
  title: string;
  artist_name: string;
  label_project: boolean | null;
  estimated_delivery_date: string | null;
  default_paywall_option: ScheduledProjectPaywallTypes;
}

interface MinimalProject {
  service_type: ProjectType;
  title: string;
  alts?: null | AltItem[];
  id: number;
}

export interface ScheduledProjectOverview extends MinimalScheduledProject {
  created: string;
  projects: MinimalProject[];
}

export interface ScheduledProject {
  outstanding_purchase_order_balance: number | bigint;
  id: number;
  users: User[];
  project_acceptances: ProjectAcceptance[];
  scheduled_revisions: ScheduledRevision[];
  created: string;
  deleted: string | null;
  refunded: string | null;
  projects: Project[];
  total_price: number;
  currency?: string;
  fees_collected: number;
  stripe_session_id: string;
  accepted: string | null;
  completed: string | null;
  title: string;
  artist_name?: string;
  is_label_project: boolean;
  has_purchase_order_pending: boolean;
  mixing_projects: MixingProject[];
  mastering_projects: MasteringProject[];
  default_paywall_option: ScheduledProjectPaywallTypes;
  outstanding_balance: number;
  stripe_invoice_id: string | null;
  team_member_ids?: number[];
  estimated_delivery_date: string | null;
}

export interface PaginatedScheduledProject {
  priority: number;
  id: number;
  currency?: string;
  title: string;
  created: string;
  accepted: string | null;
  completed: string | null;
  refunded: string | null;
  users: UserLite[];
  service_types: ProjectType[];
  project_acceptances: ProjectAcceptance[];
  outstanding_balance: number;
  total_price: number | null;
  default_paywall_option: ScheduledProjectPaywallTypes;
  user_is_primary_artist_or_engineer: boolean;
}

export enum ScheduledProjectStage {
  ALL = "ALL",
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  REFUNDED = "REFUNDED",
}

export const SCHEDULED_PROJECT_STAGE_OPTIONS = [
  { label: "All Statuses", value: ScheduledProjectStage.ALL },
  { label: "Pending", value: ScheduledProjectStage.PENDING },
  { label: "In Progress", value: ScheduledProjectStage.ACTIVE },
  { label: "Completed", value: ScheduledProjectStage.COMPLETED },
  { label: "Refunded", value: ScheduledProjectStage.REFUNDED },
];

export interface ScheduledProjectShareLink {
  id: number;
  created: string;
  deleted: string | null;
  used: string | null;
  scheduled_project_id: number;
  code: string;
  password: string;
}

export interface ScheduledProjectShareLinkOut {
  id: number;
  created: string;
  deleted: string | null;
  used: string | null;
  scheduled_project: ScheduledProject;
  code: string;
  password: string;
}

export interface CompleteTodayProjectsResponse {
  scheduled_projects: ScheduledProject[];
  mixing_tasks: APIMixingProject[];
  mastering_tasks: APIMasteringProject[];
  admin_mixing_tasks: APIMixingProject[];
  admin_mastering_tasks: APIMasteringProject[];
  recording_sessions: RecordingSession[];
}

export const transformRawScheduledProjectData = (
  data: ScheduledProject,
): ScheduledProject => {
  return {
    ...data,
    projects: data.mixing_projects
      // @ts-expect-error will be fixed after actions/scheduledprojects is correctly typed
      .map(transformRawData)
      // @ts-expect-error will be fixed after actions/scheduledprojects is correctly typed
      .concat(data.mastering_projects.map(transformRawData))

      // sort project output based on order index. ideally, this comes from the backend already sorted,
      // and we don't need to merge mixing/mastering projects together at all.
      .sort((a, b) => {
        if (a.order_index == null && b.order_index == null) {
          return a.id - b.id;
        }

        if (a.order_index == null) {
          return 1;
        }

        if (b.order_index == null) {
          return -1;
        }

        return a.order_index - b.order_index;
      }),
  };
};

export const MockScheduledProject: ScheduledProject = {
  accepted: "2022-01-08T23:59:06.404584",
  completed: null,
  created: "2022-01-08T23:59:06.406114",
  deleted: null,
  id: 24,
  project_acceptances: [],
  refunded: null,
  fees_collected: 10,
  stripe_session_id: "hi",
  title: "Untitled",
  projects: [MockMixingProject, MockMasteringProject],
  scheduled_revisions: [],
  total_price: 100.0,
  users: [MockUser, MockUser1],
  is_label_project: false,
  has_purchase_order_pending: false,
  outstanding_purchase_order_balance: 0,
  mixing_projects: [],
  mastering_projects: [],
  default_paywall_option:
    ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED,
  outstanding_balance: 0,
  stripe_invoice_id: null,
  estimated_delivery_date: "2022-01-10T23:59:06.404584",
};

export const MockScheduleOverview: ScheduledProject[] = [
  {
    accepted: "2022-01-08T23:59:06.404584",
    completed: null,
    created: "2022-01-08T23:59:06.406114",
    deleted: null,
    id: 24,
    project_acceptances: [],
    refunded: null,
    fees_collected: 0,
    stripe_session_id: "hi",
    title: "Untitled",
    projects: [MockMixingProject, MockMasteringProject],
    scheduled_revisions: [],
    total_price: 100.0,
    users: [MockUser, MockUser1],
    is_label_project: false,
    has_purchase_order_pending: false,
    outstanding_purchase_order_balance: 0,
    mixing_projects: [],
    mastering_projects: [],
    default_paywall_option:
      ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED,
    outstanding_balance: 0,
    stripe_invoice_id: null,
    estimated_delivery_date: "2022-01-10T23:59:06.404584",
  },
  {
    accepted: "2022-01-08T23:59:06.404584",
    completed: null,
    created: "2022-01-08T23:59:08.113932",
    deleted: null,
    id: 25,
    project_acceptances: [],
    refunded: null,
    fees_collected: 0,
    stripe_session_id: "hi",
    title: "Untitled",
    projects: [MockMixingProject],
    scheduled_revisions: [],
    total_price: 100.0,
    users: [MockUser, MockUser1],
    is_label_project: false,
    has_purchase_order_pending: false,
    outstanding_purchase_order_balance: 0,
    mixing_projects: [],
    mastering_projects: [],
    default_paywall_option:
      ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED,
    outstanding_balance: 0,
    stripe_invoice_id: null,
    estimated_delivery_date: "2022-01-10T23:59:06.404584",
  },
  {
    accepted: "2022-01-08T23:59:06.404584",
    completed: null,
    created: "2022-01-08T23:59:09.061368",
    deleted: null,
    id: 26,
    project_acceptances: [],
    refunded: null,
    fees_collected: 0,
    stripe_session_id: "hi",
    title: "Untitled",
    projects: [MockMasteringProject],
    scheduled_revisions: [
      {
        project_id: 120,
        revision_date: new Date(getCurrentDate()).toISOString(),
        revision_number: 1,
      },
    ],
    total_price: 100.0,
    users: [MockUser, MockUser1],
    is_label_project: false,
    has_purchase_order_pending: false,
    outstanding_purchase_order_balance: 0,
    mixing_projects: [],
    mastering_projects: [],
    default_paywall_option:
      ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED,
    outstanding_balance: 0,
    stripe_invoice_id: null,
    estimated_delivery_date: "2022-01-10T23:59:06.404584",
  },
];

export const MockSongDatesMap = new Map<string, FirstPassDate>([
  [
    "Mock Mixing Project",
    {
      date: getNDaysFromNow(3),
      availability: [0, 1.5, 2],
    },
  ],
  [
    "Mock Mastering Project",
    {
      date: getNDaysFromNow(4),
      availability: [0, 1, 3],
    },
  ],
  [
    "Untitled #3",
    {
      date: getNDaysFromNow(5),
      availability: [0, 1, 3],
    },
  ],
  [
    "Untitled #4",
    {
      date: getNDaysFromNow(6),
      availability: [0, 1, 3],
    },
  ],
  [
    "Untitled #5",
    {
      date: getNDaysFromNow(7),
      availability: [0, 1, 1],
    },
  ],
]);

const songDatesMapEntries: availability_multiplier[] = [
  [0, 0, 0],

  [0, 0, 0],

  [3, 2.5, 1],

  [3, 2.25, 1],

  [3, 2, 1],

  [3, 1.75, 1],

  [3, 1.5, 1],

  [0, 0, 1],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [0, 0, 0],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [0, 0, 0],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [0, 0, 0],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [0, 0, 0],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [0, 0, 0],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],

  [1, 1, 2],
];

export const MockAvailabilities = new Map<string, availability_multiplier>();
songDatesMapEntries.forEach((entry, i) => {
  const availability = entry;
  const date = getFormattedDate(
    new Date(getCurrentDate().getTime() + i * TIME_IN_A_DAY_MS),
  );
  MockAvailabilities.set(date, availability);
});
