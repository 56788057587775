export enum QUERY_KEYS {
  CREATE_TEAM = "create-team",
  CREATE_TEAM_INVITE = "create-team-invite",
  LOGIN = "login",
  LOGIN_UMG = "login-umg",
  LOGIN_GOOGLE = "login-google",
  LOGIN_GUEST = "login-guest",
  LOGIN_GUEST_VERIFICATION_CODE = "login-guest-code",
  SIGN_UP = "sign-up",
  CREATE_PROMO_CODE = "create-promo-code",
  GET_USER_PROMO_CODES = "get-user-promo-codes",
  GET_STUDIO_PROMO_CODES = "get-studio-promo-codes",
  CREATE_ARTIST_SURVEY = "create-artist-survey",
  FETCH_PAGINATED_SCHEDULED_PROJECTS = "fetch-paginated-scheduled-projects",
  GET_PROJECT_OVERVIEW_LINK = "get-project-overview-link",
  GET_SCHEDULED_PROJECT = "get-scheduled-project",
  FETCH_SCHEDULED_PROJECT_OVERVIEW = "fetch-scheduled-project-overview",
  GET_ITEMIZED_TRANSACTION = "get-itemized-transaction",
  GET_PAGINATED_ADMIN_ACTION_ITEMS = "get-paginated-admin-action-items",
  GET_SESSION_BOOKING_DETAILS = "get-session-booking-details",
  GET_USER_PROFILE = "get-user-profile",
  GET_REVIEWS = "get-reviews",
  GET_TROPHIES = "get-trophies",
  GET_ENGINEER_SERVICES = "get-engineer-services",
  GET_USER_GENRES = "get-user-genres",
  GET_USER_VERIFIED_MUSO_CREDITS = "get-user-verified-muso-credits",
  GET_MUSO_ASSOCIATION = "get-muso-profile",
  GET_SESSION_USER_ROLES = "get-session-user-roles",
  GET_PAGINATED_FEATURED_TRACKS = "get-paginated-featured-tracks",
  CREATE_RELEASE_FROM_SCHEDULED_PROJECT = "create-release-from-scheduled-project",
  UPLOAD_PROFILE_COVER_PHOTO = "upload-profile-cover-photo",
  GET_FILE_VERSIONS = "get-file-versions",
  FETCH_SCHEDULED_PROJECT_RELEASE = "fetch-scheduled-project-release",
  UPLOAD_ENTITY_PHOTO = "upload-entity-photo",
  FETCH_ARTIST_RELEASES = "fetch-artist-releases",
  GET_MUSO_ROSTER = "get-muso-roster",
  GET_FEATURED_TRACK_REQUEST = "get-featured-track-request",
  FETCH_ARTIST_RELEASE_TRACKS = "fetch-release-tracks",
  FETCH_TRENDING_ARTIST_RELEASE_TRACKS = "fetch-trending-tracks",
  UPLOAD_PROFILE_IMAGE = "upload-profile-image",
  GET_SUBSCRIPTION_STATUS = "get-subscription-status",
  GET_MUSO_AUTH = "get-muso-auth",
  GET_AFFILIATED_STUDIOS = "get-affiliated-studios",
  FETCH_FEATURED_ARTIST_RELEASE = "fetch-featured-artist-release",
  FETCH_FEATURED_HOME_RELEASE_TRACKS = "fetch-featured-home-release-tracks",
  FETCH_ARTIST_RELEASE = "fetch-release",
  FETCH_ARTIST_RELEASE_DETAILS = "fetch-release-details",
  DELETE_ENGINEER_SERVICE = "delete-engineer-service",
  DELETE_RECORDING_SERVICE = "delete-recording-service",
  GET_ONBOARDING_PROGRESS = "get-onboarding-progress",
  GET_OPTED_IN_UNVERIFIED_ENGINEERS = "get-opted-in-unverified-engineers",
  LOAD_USER = "load-user",
  LOAD_PROFILE_CURRENCY = "load-profile-currency",
  UPDATE_PROFILE_CURRENCY = "update-profile-currency",
  LOAD_STUDIOS = "load-studios",
  GET_USER_ASSET = "get-user-asset",
  GET_STUDIO = "get-studio",
  GET_STUDIO_ROOMS = "get-studio-rooms",
  GET_STUDIO_ROOM_RECORDING_SERVICE = "get-studio-room-recording-service",
  GET_TRENDING_ENGINEERS = "get-trending-engineers",
  GET_TRENDING_STUDIO_ROOMS = "get-trending-studio-rooms",
  GET_FREQUENTLY_ASKED_QUESTIONS = "get-frequently-asked-questions",
  GET_USER_COLLABORATOR = "get-user-collaborators",
  UPDATE_RELEASE_TRACK_ORDER = "update-release-track-order",
  UPDATE_RELEASE = "update-release",
  DOWNLOAD_TRACK_BY_FILE_VERSION_ID = "download-track-by-file-version-id",
  UPDATE_RELEASE_TRACK = "update-release-track",
  UPDATE_USER_PROFILE = "update-user-profile",
  CREATE_RECORDING_SERVICE = "create-recording-service",
  CREATE_WORKING_HOURS = "create-working-hours",
  GET_WORKING_HOURS_STUDIO = "get-working-hours-studio",
  GET_WORKING_HOURS_USER = "get-working-hours-user",
  GET_SCHEDULING_SURVEY_ANSWERS = "get-scheduling-survey-answers",
  UPDATE_SCHEDULING_SURVEY_ANSWERS = "update-scheduling-survey-answers",
  GET_USER_EQUIPMENT = "get-user-equipment",
  GET_EQUIPMENT_AUTOCOMPLETE = "get-equipment-autocomplete",
  VERIFY_PHONE = "verify-phone",
  FETCH_PRODUCTS_BY_RELEASE_ID = "fetch-products-by-release-id",
  CREATE_TRANSACTION_FOR_PRODUCTS = "create-transaction-for-products",
  FETCH_PRODUCT_TRANSACTION = "fetch-product-transaction",
  UPDATE_PRODUCT_TRANSACTION = "update-product-transaction",
  FETCH_PRODUCT_TRANSACTION_DETAILS = "fetch-product-transaction-details",
  MARK_PRODUCT_TRANSACTION_AS_PAID = "mark-product-transaction-as-paid",
  FETCH_RELEASE_TRACK_BY_ID = "fetch-release-track-by-id",
  CALCULATE_PRODUCT_TAX = "calculate-product-tax",
  GET_RESET_CODE = "get-reset-code",
  GET_SUPPLY_CHAIN_CREDENTIALS = "get-supply-chain-credentials",
  GET_SUPPLY_CHAIN_LABELS = "get-supply-chain-labels",
  GET_SUPPLY_CHAIN_GENRES = "get-supply-chain-genres",
  GET_SUPPLY_CHAIN_DASHBOARD_OPTIONS = "get-supply-chain-dashboard-options",
  UPDATE_SUPPLY_CHAIN_LABEL = "update-supply-chain-label",
  GET_SUPPLY_CHAIN_ALBUMS = "get-supply-chain-albums",
  POST_SUPPLY_CHAIN_ALBUM = "post-supply-chain-album",
  POST_SUPPLY_CHAIN_TRACK = "post-supply-chain-track",
  CHECK_IF_PASSWORDLESS_ACCOUNT = "check-if-passwordless-account",
  CHECK_IF_USERNAME_EXISTS = "check-if-username-exists",
  ENGINEER_SEARCH = "engineer-search",
  STUDIO_ROOM_SEARCH = "studio-room-search",
  FETCH_PORTFOLIO_PROJECT_TRACKS_BY_USER_ID = "fetch-portfolio-project-tracks-by-user-id",
  GET_ARTISTS_COMPLETED_PROJECTS_COUNT = "get-artists-completed-projects-count",
  POST_TRANSACTION_SHARE = "post-transaction-share",
  GET_TRANSACTION_SHARE = "get-transaction-share",
  GET_BOOKING_LINKS = "get-booking-links",
  GET_SUPPLY_CHAIN_PRODUCT_PAYEES = "get-supply-chain-product-payees",
  GET_SUPPLY_CHAIN_LABEL_PAYEES = "get-supply-chain-label-payees",
  POST_SUPPLY_CHAIN_PRODUCT_PAYEES = "post-supply-chain-product-payees",
  GET_AD_CAMPAIGN_OVERVIEW = "get-ad-campaign-overview",
  GET_AD_CAMPAIGNS = "get-add-campaigns",
  SEARCH_CAMPAIGN_LOCATION = "campaign-location-search",
  SEARCH_CAMPAIGN_INTERESTS = "campaign-interests-search",
  GET_DRAFT_CAMPAIGN = "get-draft-campaign",
  UPDATE_CAMPAIGN = "update-campaign",
  CREATE_CAMPAIGN_TRANSACTION = "create-campaign-transaction",
  GET_CAMPAIGN_TRANSACTION = "get-campaign-transaction",
  GET_CAMPAIGN_ASSETS = "get-campaign-assets",
  UPDATE_CAMPAIGN_ASSETS = "update-campaign-assets",
  FETCH_CHART_DATA = "fetch-chart-data",
  UPDATE_USER_ENGINEER = "update-user-engineer",
  MIGRATE_ANONYMOUS_ID = "migrate-anonymous-id",
  SHARE_TRANSACTION = "share-transaction",
  FETCH_CAMPAIGN_ANALYTICS = "fetch-campaign-analytics",
  FETCH_CAMPAIGN_LOCATIONS = "fetch-campaign-locations",
  POST_CAMPAIGN_LOCATION = "post-campaign-location",
  DELETE_CAMPAIGN_LOCATION = "delete-campaign-location",
  FETCH_CAMPAIGN_INTERESTS = "fetch-campaign-interests",
  POST_CAMPAIGN_INTEREST = "post-campaign-interest",
  DELETE_CAMPAIGN_INTEREST = "delete-campaign-interest",
  FETCH_CAMPAIGN_STATUS = "fetch-campaign-status",
}
