import { format } from "date-fns-tz";
import humanizeDuration from "humanize-duration";
import { OptionType } from "../../stories/elements/DropDownSelector/DropdownSelector";
import { Project, ProjectType } from "../models/project";
import { DiscountRate, Discounts, RecordingService } from "../models/recording";
import Service, { ServiceRate } from "../models/service";
import { DollarFormatter } from "./formatUtils";
import { getValidDiscountRate } from "./recordingUtils";

export const MAX_SONGS_PER_SCHEDULED_PROJECT = 30;

export function getServiceFromServiceType(
  serviceType: ProjectType,
  isEngineer?: boolean,
) {
  switch (serviceType) {
    case ProjectType.RECORDING:
      return isEngineer ? "Recording Services" : "Studio Booking";
    case ProjectType.MIXING:
      return "Mixing (Full Stems)";
    case ProjectType.MASTERING:
      return "Mastering";
    case ProjectType.TWO_TRACK_MIXING:
      return "Mixing (2-Track)";
    case ProjectType.ATMOS_MIXING:
      return "Dolby ATMOS";
    case ProjectType.NO_TYPE:
    default:
      return "";
  }
}

export function getSimpleServiceNameFromServiceType(serviceType: ProjectType) {
  switch (serviceType) {
    case ProjectType.RECORDING:
      return "Record";
    case ProjectType.MIXING:
      return "Mix";
    case ProjectType.MASTERING:
      return "Master";
    case ProjectType.TWO_TRACK_MIXING:
      return "Mix";
    case ProjectType.ATMOS_MIXING:
      return "Mix";
    case ProjectType.NO_TYPE:
    default:
      return "";
  }
}

export function getServiceDescriptionFromServiceType(serviceType: ProjectType) {
  switch (serviceType) {
    case ProjectType.RECORDING:
      return "Book in person time with this engineer";
    case ProjectType.MIXING:
      return "All beat files and vocal files separated";
    case ProjectType.MASTERING:
      return "Master your final mix for release";
    case ProjectType.TWO_TRACK_MIXING:
      return "2Track beat and vocals only";
    case ProjectType.ATMOS_MIXING:
      return "Get a Dolby Atmos audio mix";
    case ProjectType.NO_TYPE:
    default:
      return "";
  }
}

export const twoTrackMixing = (revisions: number | undefined) => {
  return [
    "Upload vocal tracks and one instrumental track for mixing",
    "Guaranteed delivery time based on scheduled mix dates",
    revisions != null
      ? `Client is eligible for ${revisions} included mix revisions`
      : "",
    "Main mix and clean alt included by default",
  ].filter(Boolean);
};

export const fullStemMixing = (revisions: number | undefined) => {
  return [
    "Upload up to 256 mono or stereo tracks/stems per song",
    "Guaranteed delivery time based on scheduled mix dates",
    revisions != null
      ? `Client is eligible for ${revisions} included mix revisions`
      : "",
    "Main mix and clean alt included by default",
  ].filter(Boolean);
};

export const masteringService = (revisions: number | undefined) => {
  return [
    "Upload 24-bit / 32-bit stereo wav",
    "Guaranteed delivery time based on scheduled master dates",
    revisions != null
      ? `Client is eligible for ${revisions} included master revisions`
      : "",
    "Mastered record returned in wav format",
  ].filter(Boolean);
};

export const atmosMixing = (revisions: number | undefined) => {
  return [
    "Upload up to 256 mono or stereo tracks/stems per song",
    "Guaranteed delivery time based on scheduled mix dates",
    revisions != null
      ? `Client is eligible for ${revisions} included mix revisions`
      : "",
    "Main mix and clean alt included by default",
    "MP4 and BIN wav (Binaural) with ADM wav (Audio Definition Model){{https://support.engineears.com/en/knowledge-base/glossary-of-terms|Read more}}",
  ].filter(Boolean);
};

export const INCLUDED_TEXT_URL_REGEX = /{{(.*?)\|(.*?)}}/;

export const getIncludedIncentiveByServiceType = (service: {
  service_type: ProjectType;
}) => {
  switch (service.service_type) {
    case ProjectType.TWO_TRACK_MIXING:
      return twoTrackMixing((service as Service).free_revisions);
    case ProjectType.MASTERING:
      return masteringService((service as Service).free_revisions);
    case ProjectType.MIXING:
      return fullStemMixing((service as Service).free_revisions);
    case ProjectType.ATMOS_MIXING:
      return atmosMixing((service as Service).free_revisions);
    case ProjectType.RECORDING:
      return recordingBooking();
    default:
      return [];
  }
};

/**
 * @deprecated
 * Only used in the component that is deprecated
 * engineears/frontend/src/stories/screens/StripePaymentScreen/StripePaymentScreen.tsx
 */
export const getIncludedIncentiveByProjectType = (project: Project) => {
  const revisions = project.revisions_available || 0;
  switch (project.service_type) {
    case ProjectType.TWO_TRACK_MIXING:
      return twoTrackMixing(revisions);
    case ProjectType.MASTERING:
      return masteringService(revisions);
    case ProjectType.MIXING:
      return fullStemMixing(revisions);
    case ProjectType.ATMOS_MIXING:
      return atmosMixing(revisions);
    case ProjectType.RECORDING:
      return recordingBooking();
    default:
      return [];
  }
};

export function getNumberAsOptionType(numberToConvert: number | undefined) {
  if (numberToConvert === undefined) {
    return undefined;
  }
  return {
    value: numberToConvert,
    label: numberToConvert.toString(),
  } as OptionType;
}

export const hourOptions = Array.from({ length: 24 }, (_, index) => {
  const value = index + 1;
  const label =
    value === 24 ? "24 hours" : humanizeDuration(value * 60 * 60 * 1000);
  return { value, label } as OptionType;
}).reduce((acc, curr) => {
  if (curr.value < 4) {
    const halfHourPointValue = curr.value + 0.5;
    return [
      ...acc,
      curr,
      {
        value: halfHourPointValue,
        label: `${halfHourPointValue} hours`,
      },
    ];
  }
  return [...acc, curr];
}, [] as OptionType[]);

export const reversedHourOptions = [...hourOptions].reverse();

/**
 * Used to determine which discount block to add when editing a Recording or Engineering Service.
 * Default minimum discount duration is 4 hours. If the minimum duration is greater than
 * 4 hours, then we need to offset the hour options by the minimum duration.
 */
export const allDiscountHourOptions = [4, 6, 8, 10, 12, 24];
export const getHourOptionsWithMinimumDuration = (
  minDurationHours: number | undefined,
  maxDurationHours: number | undefined,
) => {
  const choices: OptionType[] = [];
  allDiscountHourOptions.forEach((option) => {
    if (minDurationHours && option < minDurationHours) {
      return;
    }
    if (maxDurationHours && option > maxDurationHours) {
      return;
    }
    choices.push({
      value: option,
      label: humanizeDuration(option * 60 * 60 * 1000),
    });
  });
  return choices;
};

export const generatePreferredHoursOptions = (
  minDurationMinutes: number,
  maxDurationMinutes: number,
  userIsAandR: boolean,
  serviceRate: ServiceRate,
  discountRates?: DiscountRate[],
  useTravelPrice = false,
  currency?: string,
) => {
  let hourlyRate: number = Number(serviceRate.price) || 0;

  if (
    userIsAandR &&
    serviceRate.label_price &&
    Number(serviceRate.label_price) > 0
  ) {
    hourlyRate = Number(serviceRate.label_price);
  } else if (
    useTravelPrice &&
    serviceRate.travel_to_artist_price &&
    Number(serviceRate.travel_to_artist_price) > hourlyRate
  ) {
    hourlyRate = Number(serviceRate.travel_to_artist_price);
  }

  const hourOptions = Array.from({ length: 24 }, (_, index) => {
    return {
      value: index + 1,
      label: humanizeDuration((index + 1) * 60 * 60 * 1000),
    };
  }).reduce((acc, curr) => {
    const hours = curr.value;
    const durationMinutes = 60 * hours;
    const appliedDiscountRate = useTravelPrice
      ? undefined
      : getValidDiscountRate(discountRates, durationMinutes);
    const priceWithoutDurationDiscount = hours * hourlyRate;
    const priceWithAppliedDiscount =
      appliedDiscountRate !== undefined
        ? hours * appliedDiscountRate?.service_rate.price
        : undefined;
    const price = priceWithAppliedDiscount ?? priceWithoutDurationDiscount;
    if (curr.value < 4) {
      const hoursWithHalf = hours + 0.5;
      const halfHourPriceWithoutDurationDiscount = hoursWithHalf * hourlyRate;
      const halfHourPrice = halfHourPriceWithoutDurationDiscount;
      return [
        ...acc,
        {
          value: curr.value,
          label: `${curr.value} hours (${DollarFormatter(currency).format(price)})`,
        },
        {
          value: curr.value + 0.5,
          label: `${curr.label} and  30 minutes (${DollarFormatter(
            currency,
          ).format(halfHourPrice)})`,
        },
      ];
    }
    return [
      ...acc,
      {
        value: curr.value,
        label: `${curr.value} hours (${DollarFormatter(currency).format(price)})`,
      },
    ];
  }, [] as OptionType[]);
  const preferredHourOptions = hourOptions.filter((option) => {
    const convertedToMinutes = option.value * 60;
    return (
      convertedToMinutes >= minDurationMinutes &&
      convertedToMinutes <= maxDurationMinutes
    );
  });
  return preferredHourOptions;
};

export const convertMinutesToHourOptionType = (minutes: number): OptionType => {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  if (minutesLeft === 0) {
    return {
      value: hours,
      label: humanizeDuration(hours * 60 * 60 * 1000, {
        largest: 1,
        units: ["h"],
      }),
    } as OptionType;
  }
  const minutesLeftRounded = Math.round(minutesLeft / 15) * 15;
  const value = hours + minutesLeftRounded / 60;
  const label = humanizeDuration(value * 60 * 60 * 1000, {
    largest: 1,
    units: ["h"],
  });
  return {
    value: value,
    label: label,
  } as OptionType;
};

export function getNumberedOptions(startNumber: number, endNumber: number) {
  const options: OptionType[] = [];
  for (let i = startNumber; i < endNumber; i++) {
    options.push({ value: i, label: i.toString() });
  }
  return options;
}

export function getNumberedOptionsWithLabel(
  startNumber: number,
  endNumber: number,
  label: string,
) {
  const options: OptionType[] = [];
  for (let i = startNumber; i < endNumber; i++) {
    options.push({ value: i, label: i.toString() + " " + label });
  }
  return options;
}

export const convertIndexFromHourOptionsToMilliseconds = (index: number) => {
  const hours = Math.floor(index);
  const minutes = (index - hours) * 60;
  return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
};

export const convertIndexFromHourOptionsToMinutes = (index: number) => {
  const ms = convertIndexFromHourOptionsToMilliseconds(index);
  const seconds = ms / 1000;
  const minutes = seconds / 60;
  return minutes;
};

export const convertMillisecondsToMinutesRoundedToInt = (
  milliseconds: number,
) => {
  return Math.round(milliseconds / 60000);
};

export const convertMinutesToMilliseconds = (minutes: number) => {
  return minutes * 60 * 1000;
};

export const convertHoursToMinutes = (hours: number) => hours * 60;

const FIFTEEN = 15;
const TEN = 10;
const FIVE = 5;

export const extraRevisionCostOptions = [
  { value: FIFTEEN, label: "15%" },
  { value: TEN, label: "10%" },
  { value: FIVE, label: "5%" },
];

export const recordingBooking = () => {
  return [
    "Instantly book based on realtime availability",
    "High quality vocal recording in the studio",
    "Audio engineer included with session",
    "Session payment made easy",
  ];
};

export const convertDatesToOptionType = (
  dates: Date[],
  hourDuration: number,
  timezone?: string,
) => {
  const currentSelectedDay = dates[0];
  const allDateOptions = dates
    .filter((date) => {
      if (currentSelectedDay.getDay() !== date.getDay()) {
        return false;
      }
      return true;
    })
    .map((date) => {
      const endTime = date.getTime() + hourDuration * 60 * 60 * 1000;
      const formattedEndTime = new Date(
        date.getTime() + hourDuration * 60 * 60 * 1000,
      );
      if (date.getHours() > formattedEndTime.getHours()) {
        return {
          value: date.getTime(),
          label:
            format(date, "h:mm a") +
            format(endTime, " - h:mm a") +
            " (next day)",
        };
      }
      return {
        value: date.getTime(),
        label: format(date, "h:mm a") + format(endTime, " - h:mm a"),
      };
    });
  return allDateOptions;
};

export const convertDiscountRatesToDisplayableRates = (
  recordingService: RecordingService,
) => {
  const discountRates: { minTimeSelected: OptionType; discount: Discounts }[] =
    [];
  if (recordingService?.recording_service_discount_rate) {
    recordingService?.recording_service_discount_rate.forEach(
      (discountRate: DiscountRate) => {
        discountRates.push({
          minTimeSelected: convertMinutesToHourOptionType(
            discountRate.minimum_time_to_enable_rate,
          ),
          discount: {
            minimum_time_to_enable_rate:
              discountRate.minimum_time_to_enable_rate,
            price: Number(discountRate.service_rate.price),
          },
        });
      },
    );
  }
  return discountRates;
};

export const canAddMasteringService = (
  serviceType: ProjectType | undefined,
  eligibleServices: (Service | RecordingService)[],
) => {
  // If the selected service is full stems mixing or two track mixing,
  // and the engineer also offers a mastering service, return true.
  if (
    serviceType !== ProjectType.MIXING &&
    serviceType !== ProjectType.TWO_TRACK_MIXING
  ) {
    return false;
  }

  return eligibleServices.some(
    (service) => service.service_type === ProjectType.MASTERING,
  );
};

export const getMasteringPricePerSong = (
  service: Service | undefined,
  eligibleServices: (Service | RecordingService)[],
  useLabelPrice: boolean,
) => {
  if (!canAddMasteringService(service?.service_type, eligibleServices)) {
    return 0;
  }

  const masteringService = eligibleServices.find(
    (service) => service.service_type === ProjectType.MASTERING,
  );
  return (
    (useLabelPrice
      ? masteringService!.service_rate.label_price
      : masteringService!.service_rate.price) ??
    masteringService!.service_rate.price
  );
};

export const canAddDolbyMixingService = (
  serviceType: ProjectType | undefined,
  eligibleServices: (Service | RecordingService)[],
) => {
  // If the selected service is full stems mixing ,
  // and the engineer also offers a dolby mixing service, return true.
  if (serviceType !== ProjectType.MIXING) {
    return false;
  }

  return eligibleServices.some(
    (service) => service.service_type === ProjectType.ATMOS_MIXING,
  );
};

export const getDolbyMixingPricePerSong = (
  service: Service | undefined,
  eligibleServices: (Service | RecordingService)[],
  useLabelPrice: boolean,
) => {
  if (!canAddDolbyMixingService(service?.service_type, eligibleServices)) {
    return 0;
  }

  const dolbyMixingService = eligibleServices.find(
    (service) => service.service_type === ProjectType.ATMOS_MIXING,
  );
  return (
    (useLabelPrice
      ? dolbyMixingService!.service_rate.label_price
      : dolbyMixingService!.service_rate.price) ??
    dolbyMixingService!.service_rate.price
  );
};
