import { Box, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import React, { FormEvent, HTMLAttributes } from "react";
import { AutocompleteSuggestion } from "../../../../../store/models/autocomplete";
import { EntitySearchResultList } from "../../../../components/EntitySearchResultList/EntitySearchResultList";
import {
  HomeSearchButton,
  HomeSearchForm,
  HomeSearchInput,
  HomeSearchResults,
} from "./HomeSearchBar.styles";

const TEST_PREFIX = "home-search-bar";

interface HomeSearchBarProps extends HTMLAttributes<HTMLInputElement> {
  autocompleteSuggestions: AutocompleteSuggestion[];
  handleSearchQuery: (event: FormEvent<HTMLFormElement>) => void;
  isFetchingAutocomplete: boolean;
  onListItemClick: () => void;
  showResultsDropDown: boolean;
  searchTerm?: string;
}

/**
 * Search Bar component made for the Home Page.
 * Fetching logic lives in SitewideSearchBar component.
 */
export const HomeSearchBar = ({
  autocompleteSuggestions,
  handleSearchQuery,
  isFetchingAutocomplete,
  onBlur,
  onChange,
  onFocus,
  onListItemClick,
  showResultsDropDown,
  searchTerm,
}: HomeSearchBarProps) => {
  const theme = useTheme();
  const showAttachedDropDown = Boolean(showResultsDropDown && searchTerm);
  return (
    <Box
      sx={{ boxSizing: "border-box", position: "relative", width: "100%" }}
      data-testid={`${TEST_PREFIX}-container`}
    >
      <HomeSearchForm
        $showAttachedDropDown={showAttachedDropDown}
        onSubmit={handleSearchQuery}
        data-testid={`${TEST_PREFIX}-form`}
      >
        <HomeSearchInput
          type="text"
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder="Search EngineEars"
          value={searchTerm}
          data-testid={`${TEST_PREFIX}-form-search-term`}
        />
        <HomeSearchButton
          type="submit"
          data-testid={`${TEST_PREFIX}-form-submit`}
        >
          <FontAwesomeIcon icon={faSearch} color={theme.palette.common.white} />
        </HomeSearchButton>
      </HomeSearchForm>
      <HomeSearchResults
        data-testid={`${TEST_PREFIX}-results`}
        $showResultsDropDown={showResultsDropDown}
      >
        <EntitySearchResultList
          loading={isFetchingAutocomplete}
          data={autocompleteSuggestions}
          onListItemClick={onListItemClick}
        />
      </HomeSearchResults>
    </Box>
  );
};
