import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { activeUserAtom } from "../../atoms/user/activeUserAtom";
import { useAtomValue } from "jotai";
import { EngineEarsError } from "../../store/actions/errorStore";
import { displayEngineEarsError } from "../../api/helpers";
import { fetchProfileCurrency } from "../../api/accounts/user/fetchProfileCurrency";
import { activeProfileAtom } from "../../atoms/user/activeProfileAtom";

const useActiveProfileCurrencyQuery = () => {
  const activeUser = useAtomValue(activeUserAtom);
  const activeProfile = useAtomValue(activeProfileAtom);
  return useQuery({
    queryKey: [
      QUERY_KEYS.LOAD_PROFILE_CURRENCY,
      activeUser?.id,
      `${activeProfile?.type}:${activeProfile?.profile_id}`,
    ],
    queryFn: async () => {
      return fetchProfileCurrency({ profileId: activeProfile!.profile_id! })
        .then(async (data) => {
          return data;
        })
        .catch((e: EngineEarsError) => {
          displayEngineEarsError(e);
          throw e;
        });
    },
    retry: false,
    enabled: Boolean(activeUser?.id) && Boolean(activeProfile?.profile_id),
  });
};

export default useActiveProfileCurrencyQuery;
