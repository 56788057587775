import { add, isAfter, isBefore } from "date-fns";
import { OptionType } from "../../stories/elements/DropDownSelector/DropdownSelector";
import { RecordingFees } from "../actions/recordingCartsStore";
import { GetItemizedTransactionResponse } from "../actions/transactions";
import { DiscountRate, RecordingService } from "../models/recording";
import {
  RecordingSession,
  SessionWorkflowSteps,
  SummarizedRecordingSession,
} from "../models/recordingSession";
import { StudioRoom } from "../models/studio";
import {
  convertUTCDateToLocalDate,
  getAvailabilityFormattedDateString,
} from "./dateTimeUtils";
import {
  getDisplayableNameForStudio,
  getDisplayableNameForStudioRoom,
} from "./entityUtils";

export interface StudioRoomOptionType extends OptionType {
  studioId?: number;
  serviceId?: number;
}

export const getStudioRoomOptionFromRecordingService = (
  studioRoom: StudioRoom,
): StudioRoomOptionType | null => {
  if (studioRoom?.studio) {
    const studioName = getDisplayableNameForStudio(studioRoom.studio);
    const studioRoomName = getDisplayableNameForStudioRoom(studioRoom);
    return {
      label: `${studioRoomName}${studioName ? ` • ${studioName}` : ""}`,
      value: studioRoom.id,
      studioId: studioRoom.studio.id,
      serviceId: studioRoom.recording_service?.id,
    };
  }
  return null;
};

export const getRecordingServiceOptionsForDropDown = (
  studioRooms: StudioRoom[],
  engineerRecordingService?: RecordingService,
) => {
  const options = studioRooms
    .map((studioRoom) => getStudioRoomOptionFromRecordingService(studioRoom))
    .filter(Boolean) as StudioRoomOptionType[];

  let engineerRoomOption: OptionType | undefined;
  if (engineerRecordingService?.will_come_to_you) {
    engineerRoomOption = {
      label: "Custom session location",
      value: engineerRecordingService.id,
    } as OptionType;
  }
  if (engineerRoomOption) {
    options.push(engineerRoomOption);
  }
  return options;
};

export const getStartTimesForContiguousAvailableBlocks = (
  date: Date,
  availabilities: Map<string, string>,
  sessionDurationMinutes: number,
  fifteenMinuteIntervals = 2,
) => {
  const startTimes: Date[] = [];
  const numberOf1s = Math.floor(sessionDurationMinutes / 15);
  let availabilityString = availabilities.get(
    getAvailabilityFormattedDateString(convertUTCDateToLocalDate(date)),
  );
  if (!availabilityString) return startTimes;
  if (availabilityString.indexOf("1") === -1) return startTimes;
  const tomorrow = new Date();
  tomorrow.setDate(date.getDate() + 1);
  const nextDayString = availabilities.get(
    getAvailabilityFormattedDateString(convertUTCDateToLocalDate(tomorrow)),
  );
  availabilityString = availabilityString + nextDayString?.slice(0, numberOf1s);

  // Next, find the indices of the start times for which we have numberOf1s contiguous 1s.
  const startIndices = [];
  for (
    let index = 0;
    index <= availabilityString.length - numberOf1s;
    index += fifteenMinuteIntervals
  ) {
    const testString = availabilityString.substring(index, index + numberOf1s);
    if (testString.includes("0")) continue;
    startIndices.push(index);
  }

  // Convert start indices to starting datetimes.
  startIndices.forEach((index) => {
    startTimes.push(new Date(date.getTime() + index * 15 * 60000));
  });
  return startTimes;
};

export const getValidDiscountRate = (
  discountRates: DiscountRate[] | undefined,
  durationMinutes: number | undefined,
) => {
  if (durationMinutes === undefined) {
    return undefined;
  }
  if (discountRates === undefined) {
    return undefined;
  }
  if (discountRates.length <= 0) {
    return undefined;
  }
  return discountRates.reduce<DiscountRate | undefined>(
    (validDiscountRate, currentDiscountRate) => {
      if (
        currentDiscountRate.minimum_time_to_enable_rate <= durationMinutes &&
        (!validDiscountRate ||
          currentDiscountRate.minimum_time_to_enable_rate >
            validDiscountRate.minimum_time_to_enable_rate)
      ) {
        return currentDiscountRate;
      }
      return validDiscountRate;
    },
    undefined, // Default value is undefined
  );
};

export const getOrderSummaryFromItemizedTransactionResponse = (
  itemizedTransaction: GetItemizedTransactionResponse,
) => {
  let orderSummary = null;
  const recordingFees: RecordingFees = {};

  for (const [key, value] of Object.entries(
    itemizedTransaction.recording_service_type_map,
  )) {
    recordingFees[Number(key)] = {
      providerTotalPrice: value.total_price,
      providerCurrency: itemizedTransaction.currency,
      providerUnitPrice: value.unit_price,
      providerTotalDuration: value.duration,
      providerType: value.type,
      providerUsername: value.username,
      providerDiscountedUnitPrice: value.discounted_unit_price,
    };
  }

  if (Object.keys(recordingFees).length > 0) {
    orderSummary = {
      subtotal: itemizedTransaction.subtotal,
      fees: itemizedTransaction.fees,
      currency: itemizedTransaction.currency,
      totalPrice: itemizedTransaction.total,
      discountOrSurchargeValue: itemizedTransaction.discount_or_surcharge_value,
      discountOrSurchargePercentage:
        itemizedTransaction.discount_or_surcharge_percentage,
      outstandingBalance: itemizedTransaction.outstanding_balance,
      recordingFees,
    };
  }

  return orderSummary;
};

export const getWorkflowStep = (recordingSession: RecordingSession | null) => {
  if (!recordingSession) {
    return undefined;
  }

  const {
    first_choice_datetime: firstChoiceDateTime,
    session_duration_minutes: sessionDurationMinutes,
    refunded,
    completed,
  } = recordingSession;

  // If a session is refunded, it means it was either canceled or rejected
  // Also, If a session is "completed"
  // The stepper should be marked as COMPLETE
  if (refunded || completed) {
    return SessionWorkflowSteps.SESSION_COMPLETE;
  }

  const currentTime = new Date();
  const startTime = new Date(`${firstChoiceDateTime}Z`);
  const endTime = add(startTime, { minutes: sessionDurationMinutes });

  if (isBefore(currentTime, startTime)) {
    return SessionWorkflowSteps.SESSION_UPCOMING;
  } else if (isAfter(currentTime, endTime)) {
    return SessionWorkflowSteps.SESSION_COMPLETE;
  } else {
    return SessionWorkflowSteps.SESSION_IN_PROGRESS;
  }
};

export const checkIfSessionIsOver = ({
  first_choice_datetime: firstChoiceDateTime,
  session_duration_minutes: sessionDurationMinutes,
}: {
  first_choice_datetime: string;
  session_duration_minutes: number;
}) => {
  const currentTime = new Date();
  const startTime = new Date(`${firstChoiceDateTime}Z`);
  const endTime = add(startTime, { minutes: sessionDurationMinutes });

  return isAfter(currentTime, endTime);
};

// As of now, if there is the `project_id` property, then it's a SummarizedRecordingSession
export const isSummarizedRecordingSession = (
  session: RecordingSession | SummarizedRecordingSession,
): session is SummarizedRecordingSession => {
  return "project_id" in session;
};

export const getSessionStudioUnitNumber = (
  recordingSession: RecordingSession,
) => {
  return (
    recordingSession?.studio_room?.studio?.unit_number ||
    recordingSession?.unit_number ||
    "N/A"
  );
};

export const getSessionStudioArrivalInformation = (
  recordingSession: RecordingSession,
) => {
  return (
    recordingSession?.studio_room?.studio?.arrival_information ||
    recordingSession?.arrival_information ||
    "N/A"
  );
};
