import { FC, HTMLAttributes } from "react";
import {
  EntitySearchResultList,
  EntitySearchResultListProps,
} from "../../../../EntitySearchResultList/EntitySearchResultList";
import "./NavSearchResultsDropDown.css";

const TEST_PREFIX = "nav-search-results-dropdown";

export type NavSearchResultsDropDownProps = {
  show: boolean;
} & Pick<EntitySearchResultListProps, "loading" | "data" | "onListItemClick"> &
  Pick<HTMLAttributes<HTMLDivElement>, "className" | "style">;

export const NavSearchResultsDropDown: FC<NavSearchResultsDropDownProps> = ({
  className,
  data,
  loading,
  onListItemClick,
  show,
  style,
}: NavSearchResultsDropDownProps) => {
  return (
    <div
      className={`nav-search-results-container ${className} ${show ? "show" : ""}`}
      style={style}
      data-testid={`${TEST_PREFIX}-container`}
    >
      <EntitySearchResultList
        className="nav-entity-search-result-list"
        loading={loading}
        data={data}
        onListItemClick={onListItemClick}
      />
    </div>
  );
};
