export enum PlaybackControlButtonSize {
  SMALL = "sm",
  REGULAR = "lg",
  LARGE = "2xl",
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/material";

export const StyledPlaybackControlButtonFontAwesomeIcon = styled(
  FontAwesomeIcon,
)(({ theme }) => ({
  cursor: "pointer",
  color: theme.palette.text.secondary,
  // Order matters for the following classes
  "&.primary": {
    color: theme.palette.text.primary,
  },
  "&.toggled": {
    color: theme.palette.standardColor.Gray[300],
  },
  "&.disabled": {
    color: theme.palette.text.disabled,
  },
  "@media (pointer: fine)": {
    "&:hover": {
      color: theme.palette.standardColor.Gray[50],
    },
  },
}));

export const StyledPlaybackControlButtonRepeatActiveIcon = styled("div")(
  ({ theme }) => ({
    zIndex: "var(--footer-player-z-index)",
    position: "absolute",
    width: "4px",
    height: "4px",
    left: "40%",
    top: "40%",
    borderRadius: "50%",
    backgroundColor: theme.palette.text.secondary,
    // Order matters for the following classes
    "&.primary": {
      backgroundColor: theme.palette.text.primary,
    },
    "&.toggled": { backgroundColor: theme.palette.standardColor.Gray[300] },
    "@media (pointer: fine)": {
      "&:hover": {
        backgroundColor: theme.palette.standardColor.Gray[50],
      },
    },
    [`&.${PlaybackControlButtonSize.SMALL}`]: {
      width: "2px",
      height: "2px",
    },
  }),
);
