const validateCurrency = (currency: string) =>
  (currency || "USD").toUpperCase();

export const DollarFormatter = function (currency = "usd"): Intl.NumberFormat {
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: validateCurrency(currency),
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const PennyDollarFormatter = function (
  currency = "usd",
): Intl.NumberFormat {
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: validateCurrency(currency),
    minimumFractionDigits: 2,
  });
};

// This formatter forces the display string to have the following format, regardless of the user's locales
// E.g: 1234 -> $1,234
export const DollarNarrowFormatter = function (
  currency = "USD",
): Intl.NumberFormat {
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: validateCurrency(currency),
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};
