import { useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { displayEngineEarsError } from "../../api/helpers";
import { EngineEarsError } from "../../store/actions/errorStore";
import { postProfileCurrency } from "../../api/accounts/user/postProfileCurrency";

interface UseProfileCurrencyMutationArgs {
  profileId: number;
  currencyCode: string;
}

const useProfileCurrencyMutation = () => {
  return useMutation({
    mutationKey: [QUERY_KEYS.UPDATE_PROFILE_CURRENCY],
    mutationFn: async ({
      currencyCode,
      profileId,
    }: UseProfileCurrencyMutationArgs) => {
      return postProfileCurrency({ profileId, currencyCode: currencyCode });
    },
    onError: (e: EngineEarsError) => {
      displayEngineEarsError(e);
    },
  });
};

export default useProfileCurrencyMutation;
