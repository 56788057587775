import { Grid, Skeleton, useTheme } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useDebounceCallback, useResizeObserver } from "usehooks-ts";
import { ProductResponse } from "../../../api/products/productsAPI";
import { useDownloadReleaseTrackMutation } from "../../../api/releases/hooks/useDownloadReleaseTrackMutation";
import { useFetchReleaseTracksQuery } from "../../../api/releases/hooks/useFetchReleaseTracksQuery";
import { useProductCart } from "../../../atoms/products/hooks/useProductCart";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { UploadType } from "../../../store/models/fileVersion";
import {
  Release,
  ReleaseTrack,
  ReleaseType,
} from "../../../store/models/release";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { Button } from "../../core-ui/components/Button/Button";
import { ButtonVariant } from "../../core-ui/components/Button/utils";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import { BundleDropdown } from "../BundleDropdown/BundleDropdown";
import { ReleaseListItemCoverPhoto } from "../ReleaseList/ReleaseListItemCoverPhoto";
import { Size } from "../SessionScheduleGuide/SessionScheduleGuide";
import { FeaturedAristReleaseHeaderDetails } from "./FeaturedAristReleaseHeaderDetails";
import { FeaturedArtistReleaseHeaderDetailsLoader } from "./FeaturedArtistReleaseHeaderDetailsLoader";
import { FeaturedArtistReleasePhotoViewContainer } from "./styles";

interface FeaturedArtistReleaseProps {
  release?: Release | null;
  isReleaseLoading: boolean;
  setSelectedProductIndex?: Dispatch<SetStateAction<number | "">>;
  selectedProductIndex?: number | "";
  products?: ProductResponse[];
  releaseTracks?: ReleaseTrack[];
  onClick?: (releaseId?: number) => void;
  ctaText?: string;
  isOnReleaseDetailsView?: boolean;
}

export const FeaturedArtistRelease = ({
  release,
  isReleaseLoading,
  onClick,
  ctaText = "Buy Project",
  setSelectedProductIndex,
  selectedProductIndex,
  products = [],
  releaseTracks,
  isOnReleaseDetailsView = false,
}: FeaturedArtistReleaseProps) => {
  const theme = useTheme();
  const featured = Boolean(release?.featured);
  const { data: tracks } = useFetchReleaseTracksQuery(release?.id ?? 0);
  const { mutate: downloadReleaseTrack } = useDownloadReleaseTrackMutation();
  const canDownloadWav = tracks.some(
    (track) => track.audio_download_wav_enabled,
  );
  const canDownloadMP3 = tracks.some(
    (track) => track.audio_download_mp3_enabled,
  );
  const { isMobile } = useMediaQueryBreakpoint();
  const { handleReplaceBundle, handleRemoveBundle } = useProductCart();

  const productBundles = products.reduce((acc, product) => {
    acc.push({
      value: product.id,
      label: `${PennyDollarFormatter(product.currency).format(
        Number(product.total_price),
      )} | ${product.name}`,
    });
    return acc;
  }, [] as OptionType<number>[]);

  const ref = useRef<HTMLDivElement>(null);
  const [{ width }, setSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });

  const onResize = useDebounceCallback(setSize, 100);

  useResizeObserver({
    ref,
    onResize,
  });

  useLayoutEffect(() => {
    if (ref.current) {
      setSize({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });
    }
  }, [width]);

  if (!isReleaseLoading && !release) {
    return null;
  }

  return (
    <>
      <Grid
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        alignSelf={"stretch"}
        container
        spacing={2}
      >
        <Grid borderRadius={theme.border.radius.md} item xs={12} md={4}>
          <FeaturedArtistReleasePhotoViewContainer ref={ref}>
            {isReleaseLoading && (
              <Skeleton
                variant={"rounded"}
                width={isMobile ? "100%" : width}
                height={isMobile ? "unset" : width}
                style={{
                  aspectRatio: 1,
                }}
              />
            )}
            {release && (
              <ReleaseListItemCoverPhoto
                width={width}
                height={width}
                releaseCover={release.release_cover}
                noExplicitWidth={isMobile}
              ></ReleaseListItemCoverPhoto>
            )}
          </FeaturedArtistReleasePhotoViewContainer>
        </Grid>
        <Grid
          item
          minHeight={!isMobile ? (width ? width + 16 : width) : 0}
          xs={12}
          md={8}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          {release && (
            <FeaturedAristReleaseHeaderDetails
              title={release?.title || ""}
              artistUsername={release?.artist_user?.username || ""}
              releaseType={release?.release_type || ReleaseType.NO_RELEASE_TYPE}
              createdAt={release?.created_at || ""}
              featured={featured}
              releaseId={release?.id}
              description={release?.description || ""}
              isOnReleaseDetailsView={isOnReleaseDetailsView}
            />
          )}
          {isReleaseLoading && <FeaturedArtistReleaseHeaderDetailsLoader />}
          <Grid
            marginTop={"auto"}
            display={"flex"}
            sx={{
              flexDirection: "row",
              justifyContent: "flex-end",
              alignSelf: "flex-end",
              [theme.breakpoints.down("sm")]: {
                justifyContent: "space-between",
                flexDirection: "column",
              },
              width: isMobile ? "100%" : "unset",
              maxHeight: isMobile ? "unset" : "44px",
            }}
            item
            m={0}
            mt={2}
            xs={12}
            gap={2}
          >
            {!isMobile && (canDownloadWav || canDownloadMP3) ? (
              <Button
                style={{
                  minWidth: isMobile ? "100%" : "150px",
                }}
                fullWidth={isMobile}
                onClick={() => {
                  const uploadType = canDownloadMP3
                    ? UploadType.GENERATED_MP3
                    : canDownloadWav
                      ? UploadType.AUDIO
                      : null;

                  if (uploadType) {
                    downloadReleaseTrack({
                      releaseId: release?.id || 0,
                      uploadType,
                    });
                  }
                }}
                disabled={isReleaseLoading}
                variant={ButtonVariant.OUTLINED}
              >
                Download All
              </Button>
            ) : null}
            {products?.length > 0 && selectedProductIndex !== undefined && (
              <BundleDropdown
                selectedBundleId={selectedProductIndex}
                bundlesOptions={productBundles}
                updateSelectedBundleId={(id) => {
                  if (setSelectedProductIndex && releaseTracks) {
                    setSelectedProductIndex(id);
                    // Only replace the bundle in the cart if it's not purchased
                    if (
                      products.find(
                        (product) =>
                          product.id === id &&
                          !product.product_purchased_by_current_user,
                      )
                    ) {
                      handleReplaceBundle(
                        id,
                        releaseTracks,
                        products.map((product) => product.id),
                      );
                      // Else, we want to select a purchased product, we need to remove
                      // the currently selected product from the cart
                    } else {
                      if (selectedProductIndex) {
                        handleRemoveBundle(selectedProductIndex);
                      }
                    }
                  }
                }}
              />
            )}
            {/* Temporarily hide play count */}
            {/* {isReleaseDetailsInitialLoading ? (
            <Skeleton width={"10%"} />
          ) : (
            <Text bold variant={TextStyleVariant.P2}>
              {`${playCount} plays`}
            </Text>
          )} */}
            {onClick && !isMobile && (
              <Button
                disabled={isReleaseLoading}
                fullWidth={isMobile}
                variant={ButtonVariant.PRIMARY}
                onClick={() => {
                  onClick(release?.id);
                }}
              >
                {ctaText}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
