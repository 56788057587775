import {
  GenerateBookingInfoButtonGroup,
  GenerateBookingInfoCardContainer,
  GenerateBookingInfoContainer,
} from "./GenerateBookingInfo.styles";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { ScheduledProjectPaywallTypes } from "../../../store/models/project";
import { GenerateBookingCard } from "../../elements/GenerateBookingCard/GenerateBookingCard";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { setPaywallOption } from "../../../store/actions/generateBookingStore";
import { toggleInProgressProject } from "../../../store/actions/mixMasterCartsStore";
import { useHandleCreateTransaction } from "../../../hooks/useHandleCreateTransaction";
import useActiveProfileCurrencyQuery from "../../../hooks/userHooks/useActiveProfileCurrencyQuery";

interface GenerateBookingInfoProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
const generateBookingOptions = Object.values(
  ScheduledProjectPaywallTypes,
).filter(
  (value): value is ScheduledProjectPaywallTypes => typeof value === "number",
);

export const GenerateBookingInfo = ({
  isOpen,
  setIsOpen,
}: GenerateBookingInfoProps) => {
  const [selected, setSelected] = useState<ScheduledProjectPaywallTypes>(
    ScheduledProjectPaywallTypes.TRACK_PREVIEW_ALLOWED,
  );
  const dispatch = useAppDispatch();

  const { user: loggedInUser, localUTMParams } = useAppSelector(
    (state) => state.accountInfo,
  );

  const { data: activeProfileCurrency, isLoading: isLoadingCurrency } =
    useActiveProfileCurrencyQuery();

  const { isDesktop } = useMediaQueryBreakpoint();

  const { isCreatingTransaction, createTransactionAndNavigate } =
    useHandleCreateTransaction(
      localUTMParams,
      loggedInUser,
      loggedInUser?.engineer,
      activeProfileCurrency?.currency,
    );

  const handleConfirm = () => {
    if (
      selected !== ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED
    ) {
      dispatch(toggleInProgressProject());
    }
    dispatch(setPaywallOption(selected));
    setSelected(ScheduledProjectPaywallTypes.TRACK_PREVIEW_ALLOWED);
    createTransactionAndNavigate();
    setIsOpen(false);
  };

  return (
    <Dialog
      fullScreen={!isDesktop}
      maxWidth={"lg"}
      open={isOpen}
      onBackdropClick={() => {
        setSelected(ScheduledProjectPaywallTypes.TRACK_PREVIEW_ALLOWED);
        setIsOpen(false);
      }}
      onClose={() => setIsOpen(false)}
    >
      <DialogTitle>
        <Text variant={TextStyleVariant.H6}>When should the client pay?</Text>
      </DialogTitle>
      <DialogContent dividers={true} style={{ borderBottom: "none" }}>
        <GenerateBookingInfoContainer>
          <Text
            variant={TextStyleVariant.S3}
            style={{
              textAlign: "center",
              textWrap: "wrap",
              maxWidth: !isDesktop ? "400px" : "unset",
            }}
          >
            Choose the type of custom booking link to create for your client
            below:
          </Text>
          <GenerateBookingInfoCardContainer>
            {generateBookingOptions.map((option) => (
              <GenerateBookingCard
                key={option}
                type={option}
                selected={selected === option}
                onClick={() => {
                  if (isCreatingTransaction) {
                    return;
                  }
                  if (selected === option) {
                    emitAnalyticsTrackingEvent("confirm_generate_booking", {
                      selected: selected,
                    });
                    handleConfirm();
                    return;
                  }
                  emitAnalyticsTrackingEvent("clicked_generate_booking_card", {
                    type: option,
                  });
                  setSelected(option);
                }}
              />
            ))}
          </GenerateBookingInfoCardContainer>
        </GenerateBookingInfoContainer>
      </DialogContent>
      <DialogActions sx={{ outline: "none" }}>
        <GenerateBookingInfoButtonGroup>
          <Button
            analyticsEvent={{
              name: "cancel_generate_booking",
              data: { selected: selected },
            }}
            variant={ButtonVariant.OUTLINED}
            style={{ minWidth: "140px" }}
            loading={isCreatingTransaction}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            analyticsEvent={{
              name: "confirm_generate_booking",
              data: { selected: selected },
            }}
            style={{ minWidth: "140px" }}
            onClick={handleConfirm}
            disabled={isLoadingCurrency}
            loading={isCreatingTransaction}
          >
            Next
          </Button>
        </GenerateBookingInfoButtonGroup>
      </DialogActions>
    </Dialog>
  );
};
