import { Box, BoxProps } from "@mui/material";
import {
  SERVICE_FORM_MODAL_FOOTER_ID,
  useBottomTabBarOverlayView,
} from "../../../components/Navigation/BottomNav/useBottomTabBarOverlayView";

interface MainProjectWorkflowButtonRowProps extends BoxProps {
  containerClassName?: string;
}

export const ServicesFormModalFooter = ({
  children,
  containerClassName,
  sx = {},
  ...props
}: MainProjectWorkflowButtonRowProps) => {
  return useBottomTabBarOverlayView(
    // Since we want it to appear on all screen, `isMobile` should be true all the time
    true,
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        columnGap: "16px",
        padding: "24px 0",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>,
    containerClassName,
    SERVICE_FORM_MODAL_FOOTER_ID,
  );
};
